import React from 'react'

interface LayoutProps {
  children: React.ReactNode
  className?: string
}

const Layout = (props: LayoutProps) => {
  return (
    <main className={`${props.className} w-full flex flex-col relative`}>{props.children}</main>
  )
}

export default Layout
