import { Answer } from '@/types/types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface RateComponentProps {
  name: string
  count: number
  onAnswer: (answer: Answer, step?: number) => void
}

const RateComponent = (props: RateComponentProps) => {
  const numbersArray = Array.from({ length: props.count }, (_, index) => index + 1)
  const [active, setActive] = useState<number | undefined>(undefined)
  const { t } = useTranslation()
  const handleChange = (value: number) => {
    setActive(value)
  }

  useEffect(() => {
    active && props.onAnswer({ [props.name]: [active.toString()] })
  }, [active])

  return (
    <div className="flex flex-col gap-y-1 pt-4 max-mini:pt-3 px-2 min-[400px]:px-4">
      <div className="flex flex-row justify-between">
        {numbersArray.map((value) => (
          <div
            key={value}
            className={`${active === value ? 'bg-web2-primary-300 border-primary' : 'bg-neutral-100 border-web2-border  '} border flex w-[56px] h-[56px] rounded-full justify-center items-center cursor-pointer transition-all text-center text-xl3 leading-[30.72px] font-semibold duration-300`}
            onClick={() => handleChange(value)}
          >
            {value}
          </div>
        ))}
      </div>
      <div className="w-full flex flex-row justify-between items-center [&>p]:max-mini:text-md [&>p]:font-normal">
        <p>{t('Disagree')}</p>
        <p>{t('Agree')}</p>
      </div>
    </div>
  )
}

export default RateComponent
