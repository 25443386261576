import { Answer } from '@/types/types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface SliderComponentProps {
  name: string
  onAnswer: (answer: Answer, step?: number) => void
  triggerNextStep: () => void
  step: number
}

export default function SliderComponent(props: SliderComponentProps) {
  const { t } = useTranslation()
  const ValuesEnum: Array<string> = [t('Disagree'), t('So-so'), t('Agree')]
  const [value, setValue] = useState<string>(ValuesEnum[1])

  useEffect(() => {
    props.onAnswer(
      {
        [props.name]: [value],
      },
      props.step,
    )
    props.triggerNextStep()
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value)
    let updatedValue: string = ValuesEnum[2]
    if (newValue <= 33) {
      updatedValue = ValuesEnum[0]
    } else if (newValue >= 67) {
      updatedValue = ValuesEnum[2]
    } else {
      updatedValue = ValuesEnum[1]
    }
    setValue(updatedValue)
    props.onAnswer({
      [props.name]: [updatedValue],
    })
  }

  return (
    <div className="mt-10 mb-2">
      <div className="w-full flex flex-row justify-between items-center px-2 ">
        {ValuesEnum.map((value, index) => (
          <div
            className="w-16 text-center cursor-pointer last:text-right first:text-left"
            key={index}
            onClick={() => setValue(ValuesEnum[index])}
          >
            {value}
          </div>
        ))}
      </div>
      <div className="px-4 py-2">
        <input
          type="range"
          min="0"
          max="100"
          step={50}
          value={value === ValuesEnum[0] ? 0 : value === ValuesEnum[1] ? 50 : 100}
          onChange={handleChange}
          className="slider-input w-full"
        />
      </div>
    </div>
  )
}
