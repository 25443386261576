import { Answer, PreliminaryProcessingInterface, RadioButtonType } from '@/types/types'
import React, { useEffect, useState } from 'react'
import ProgressQuestion from './ProgressQuestion'

interface PreliminaryProcessingProps {
  attributes: PreliminaryProcessingInterface
  triggerNextStep: () => void
  curent: boolean
  name: string
  onAnswer: (answer: Answer, step?: number) => void
}

const PreliminaryProcessing = (props: PreliminaryProcessingProps) => {
  const [progress, setProgress] = useState(0)
  const [firstIntervalFilled, setFirstIntervalFilled] = useState(false)
  const [answered, setAnswered] = useState(false)
  const [modalAnswer, setModalAnswer] = useState<RadioButtonType | undefined>(undefined)
  const handle = () => {
    modalAnswer !== undefined &&
      props.onAnswer({
        [props.name]: [modalAnswer.text + (modalAnswer.img ? 'img:' + modalAnswer.img : '')],
      })
    props.triggerNextStep()
    setProgress(0)
  }

  useEffect(() => {
    if (!props.curent) return
    const startFirstHalfTimer = async () => {
      const interval = setInterval(() => {
        setProgress((prevProgress) => prevProgress + 10000 / props.attributes.duration)
      }, 100)

      setTimeout(
        () => {
          clearInterval(interval)
          setFirstIntervalFilled(true)
        },
        (props.attributes.duration / 100) * props.attributes.stopPercent,
      )

      return () => clearInterval(interval)
    }
    const startSecondHalfTimer = async () => {
      const interval = setInterval(() => {
        setProgress((prevProgress) => prevProgress + 10000 / props.attributes.duration)
      }, 100)

      setTimeout(
        () => {
          clearInterval(interval)
          setFirstIntervalFilled(false)
          setAnswered(false)
          handle()
        },
        props.attributes.duration -
          (props.attributes.duration / 100) * (props.attributes.stopPercent - 1),
      )

      return () => clearInterval(interval)
    }

    !answered ? startFirstHalfTimer() : startSecondHalfTimer()
  }, [props.curent, answered])

  const handleAnswer = (answer: RadioButtonType) => {
    setModalAnswer(answer)
    setAnswered(true)
  }
  return (
    <>
      <div className="flex flex-col w-full mt-[104px] gap-y-2 text-center">
        <p className="text-questionName max-mini:text-lg max-mini:leading-6 font-medium text-dark-200 ">
          {props.attributes?.text}
        </p>
        <div className="progress-bar !border-0 relative !bg-web2secondary-100">
          <div
            className="progress !h-8 bg-quiz2progressMain rounded-2xl"
            style={{ width: `${progress}%` }}
          >
            <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 inline-block font-normal text-progressBlock max-mini:text-md max-mini:leading-[1.15rem] text-black">
              {progress.toFixed(0)}%
            </span>
          </div>
        </div>
        {firstIntervalFilled && !answered && (
          <ProgressQuestion handleAnswer={handleAnswer} question={props.attributes.question} />
        )}
      </div>
    </>
  )
}

export default PreliminaryProcessing
