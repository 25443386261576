import { GoalImageBlockType } from '@/types/types'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

interface GoalImageBlockProps {
  goalImageBlock: GoalImageBlockType[]
  variantForImage: string
  motivation: string
  setPlanAnchor: (y: HTMLDivElement | undefined) => void
  isDiscount45: string | boolean
}

const GoalImageBlock = (props: GoalImageBlockProps) => {
  const scrollTestRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()
  useEffect(() => {
    if (scrollTestRef.current) {
      props.setPlanAnchor(scrollTestRef.current)
    }
  }, [scrollTestRef])

  const textForTicker = (): string => {
    if (props.isDiscount45 !== true) {
      return t('Your personal program is ready')
    } else {
      return t('Get your extra -70% discount')
    }
  }
  const { goalImageBlock, variantForImage, motivation } = props

  return (
    <div className="flex flex-col">
      <div className="relative flex flex-col">
        <div className="absolute w-full h-full flex justify-end z-0">
          <img src={'/img/paywall-bg.png'} alt="" />
        </div>
        <div className="flex flex-row z-10 max-h-[240px]">
          {goalImageBlock &&
            goalImageBlock.find((item: any) => item.keyword === variantForImage) && (
              <div className="flex flex-row">
                <div>
                  <picture>
                    <source
                      srcSet={
                        goalImageBlock.find((item: any) => item.keyword === variantForImage)
                          ?.beforeImgPathWebP
                      }
                      type="image/webp"
                    />
                    <img
                      width={180}
                      height={250}
                      src={
                        goalImageBlock.find((item: any) => item.keyword === variantForImage)
                          ?.beforeImgPath
                      }
                      className="w-full"
                      alt="Before"
                    />
                  </picture>
                </div>
                <div>
                  <picture>
                    <source
                      srcSet={
                        goalImageBlock.find((item: any) => item.keyword === variantForImage)
                          ?.afterImgPathWebP
                      }
                      type="image/webp"
                    />
                    <img
                      width={180}
                      height={250}
                      className="w-full"
                      src={
                        goalImageBlock.find((item: any) => item.keyword === variantForImage)
                          ?.afterImgPath
                      }
                      alt="After"
                    />
                  </picture>
                </div>
              </div>
            )}
        </div>
        <div className="h-10 flex items-center backdrop-blur-3xl z-10" ref={scrollTestRef}>
          <div className="ticker">
            <div className="ticker__wrapper">
              <div className="ticker__item">{t(textForTicker())}</div>
              <div className="ticker__item">{t(textForTicker())}</div>
              <div className="ticker__item">{t(textForTicker())}</div>
              <div className="ticker__item">{t(textForTicker())}</div>
              <div className="ticker__item">{t(textForTicker())}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row">
        <div className="flex items-start justify-center w-1/2 pt-4 px-2 pb-6 border-r border-r-grayBlue">
          <div className="flex flex-col items-center justify-start gap-y-1">
            <img src="/img/icon-motivation.svg" alt="" className="w-6 h-6" />
            <h4 className="font-semibold text-progressBlock text-center">
              {t('Your Motivation:')}
            </h4>
            <p className="font-normal text-progressBlock flex flex-row gap-x-1">
              {motivation && motivation.split(' ')[0]}
              {motivation &&
                motivation.split(' ').map((word, index) => (index !== 0 ? ' ' + word : ''))}
            </p>
          </div>
        </div>
        <div className="flex items-start justify-center w-1/2 pt-4 pb-6">
          <div className="flex flex-col items-center justify-start gap-y-1">
            <img src="/img/icon-aim.svg" alt="" className="w-6 h-6" />
            <h4 className="font-semibold text-progressBlock">{t('Your Aim:')}</h4>
            <p className="font-normal text-progressBlock">{variantForImage}</p>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  )
}

export default GoalImageBlock
