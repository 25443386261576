import React, { useEffect, useRef, useState } from 'react'
import { Answer, InputType } from '@/types/types'
import { useTranslation } from 'react-i18next'
import { Button } from '../Button/Button'
import { preCreateAccount } from '@/helpers/preCreateAccount'
import { trackEmail } from '@/helpers/facebookPixelEvents'

interface InputProps {
  attributes: InputType
  onAnswer: (answer: Answer, step?: number) => void
  handleRemoveAnswer: (answer: Answer) => void
  name: string
  step: number
  active?: boolean
  error: string
  setError: React.Dispatch<React.SetStateAction<string>>
  triggerNextStep: () => void
}

export default function Input(props: InputProps) {
  const { t } = useTranslation()
  const inputRef = useRef<HTMLInputElement>(null)
  const [showError, setShowError] = useState(props.error)

  const [isFetching, setIsFetching] = useState<boolean>(false)

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowError('')
    props.setError('')
    let isValid = e.target.checkValidity()
    if (props.attributes.type === 'email') {
      isValid = /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/.test(e.target.value)
    }
    if (props.attributes.name === 'your-name') {
      isValid = /^(?=.{2,35}$)[A-Za-z ]+$/.test(e.target.value)
    }
    if (isValid) {
      props.onAnswer({ [props.name]: [e.target.value] })
      setShowError('')
    } else {
      props.handleRemoveAnswer({ [props.name]: [] })
      if (props.attributes.type === 'email') {
        setShowError(t('E-mail doesnt look valid. Please, check again'))
      }
      if (props.attributes.name === 'your-name') {
        setShowError(t('Please, add your name to create a profile'))
      }
    }
  }

  useEffect(() => {
    if (props.attributes.name === 'your-name' && props.active) {
      inputRef.current?.focus()
    }
  }, [props.active])

  useEffect(() => {
    if (props.error && props.attributes.name === 'email') {
      setShowError(props.error)
    }
  }, [props.error])

  useEffect(() => {
    props.onAnswer({ [props.name]: [] }, props.step)
  }, [])

  const successSubmit = (customerPortalID: string, userEmail: string) => {
    localStorage.setItem('customerPortalId', customerPortalID)
    trackEmail('mail_1', userEmail)
    props.triggerNextStep()
  }

  const handleClickGetMyPlanQuiz2 = async () => {
    setIsFetching(true)
    if (inputRef.current !== null) {
      const userEmail = inputRef.current.value
      const customerPortalId = localStorage.getItem('customerPortalId')
      if (customerPortalId) {
        const data = {
          email: userEmail,
          _id: customerPortalId,
        }
        preCreateAccount(data, 'PATCH').then((res) => {
          setIsFetching(false)
          if (res.body.error === '"email" must be a valid email') {
            props.setError('Email is not valid')
          } else if (res.status !== 200) {
            props.setError('Email is not valid')
          } else {
            successSubmit(customerPortalId, data.email)
          }
        })
      } else {
        const data = { email: userEmail }
        preCreateAccount(data, 'POST').then((res) => {
          setIsFetching(false)
          if (res.body.id) {
            successSubmit(res.body.id, data.email)
          } else {
            if (res.body.error === '"email" must be a valid email') {
              props.setError('Email is not valid')
            }
          }
        })
      }
    }
  }

  return (
    <div className="w-full flex flex-col gap-y-8 max-mini:gap-y-6">
      <div>
        {props.attributes.label && !showError && (
          <label className="font-normal italic  mt-6 max-mini:mt-4 text-xs2">
            {props.attributes.label}
          </label>
        )}
        {showError && (
          <label className="font-normal italic mt-6 text-xs2 text-secondary">{showError}</label>
        )}
        <input
          ref={inputRef}
          type={props.attributes.type}
          onChange={(e) => handleChangeInput(e)}
          placeholder={props.attributes.placeholder}
          minLength={props.attributes.minLength}
          required={props.attributes.required}
          className={`${showError ? ' border-mark ' : ' border-border '} w-[108%] -ml-[4%] text-input max-mini:text-lg mini:leading-7 pl-[4%] pr-6 pb-3 font-semibold border-b rounded-none bg-transparent text-web2-neutral-700 placeholder:text-web2-border focus-visible:outline-none`}
        />
      </div>
      {props.attributes.getMyPlanButton && (
        <div className="w-full">
          <Button
            onClick={handleClickGetMyPlanQuiz2}
            disabled={
              isFetching ||
              props.error.length > 0 ||
              showError.length > 0 ||
              (inputRef.current !== null && inputRef.current.value.trim().length === 0)
            }
            className="w-full !text-questionName max-mini:!text-lg max-mini:!leading-6 disabled:bg-web2-primary-100 disabled:border-web2-neutral-300 bg-web2-primary-500 border-primary text-primary"
          >
            {isFetching ? t('Loading...') : props.attributes.getMyPlanButton}
          </Button>
        </div>
      )}
    </div>
  )
}
