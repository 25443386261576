import React, { useEffect, useState } from 'react'
import { Button } from '@/components/Button/Button'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import useValidationMessages from '@/customHooks/useValidationMessages'
import { Answers } from '@/types/types'
import { getValue } from '@/helpers/getObjectValue'
import { useTranslation } from 'react-i18next'
import { createAccount } from '@/helpers/createAccount'
import { trackEmail, trackPurchase } from '@/helpers/facebookPixelEvents'

interface IFormInput {
  name: string
  email: string
  password: string
}

export default function CreateAccount(props: { testMode?: boolean }) {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const validationErrors = useValidationMessages()
  const storedData = localStorage.getItem('quizResult')
  const isSuccessPaddle = searchParams.get('success')
  const answers: Answers = storedData ? JSON.parse(storedData) : false
  const customerPortalID = localStorage.getItem('customerPortalId') as string
  const [userEmail, setUserEmail] = useState(getValue('email', answers) as string)
  const initialName = (getValue('your-name', answers) as string) ?? ('' as string)
  const schema = yup.object().shape({
    name: yup.string().required(validationErrors.requiredName),
    email: yup
      .string()
      .required(validationErrors.requiredEmail)
      .matches(/^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/, validationErrors.invalidEmail),
    password: yup
      .string()
      .required(validationErrors.requiredPassword)
      .min(8, validationErrors.minLengthPassword),
  })
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>({ resolver: yupResolver(schema), mode: 'onChange' })

  const [name, setName] = useState(initialName)
  const [password, setPassword] = useState('')
  const [registerErrors, setRegisterErrors] = useState('')
  const location = useLocation()
  const navigate = useNavigate()
  const result =
    location.state?.res || localStorage.getItem('subscriptionInfo') || isSuccessPaddle === 'true'
  const refreshToken = localStorage.getItem('refreshToken')
  const inputClasses =
    'text-primary text-input px-6 pb-2 font-semibold border-b bg-transparent w-full placeholder:text-neutral-400 focus-visible:outline-none'
  const labelClasses = 'font-normal italic ml-6 mt-6 text-xs2'

  useEffect(() => {
    if (!props.testMode && result === undefined) {
      navigate('/')
    }
    if (refreshToken) {
      navigate('/thank-you', { state: { token: refreshToken, customerPortalID } })
    } else {
      const hasPurchase = localStorage.getItem('hasPurchase')
      if (hasPurchase === null) {
        localStorage.setItem('hasPurchase', 'has')
        trackPurchase()
      }
    }
  }, [result])

  const onSubmit = (data: IFormInput) => {
    const customer = { email: data.email, password: data.password }
    trackEmail('mail_2', data.email as string)
    createAccount(customer, customerPortalID).then((res) => {
      if (res.status !== 412) {
        if (res.status === 200) {
          const token = res.body.refresh
          localStorage.setItem('refreshToken', token)
          localStorage.setItem('customer', JSON.stringify(customer))
          navigate('/thank-you', { state: { token, customer } })
        } else if (res.status === 409) {
          setRegisterErrors(res.body.error)
        } else {
          console.log('error', res.body.error)
          setRegisterErrors(res.body.error)
        }
      }
    })
  }

  const handleInputChange = (fieldName: any, value: string) => {
    setValue(fieldName, value, { shouldValidate: true })
    if (fieldName === 'name') {
      setName(value)
    }
    if (fieldName === 'password') {
      setPassword(value)
    }
    if (fieldName === 'email') {
      setUserEmail(value)
    }
  }

  return (
    <div className="w-full py-6 pt-10 px-4 flex flex-col">
      <h2 className="text-questionStartName text-center text-black font-semibold pt-0 mb-1">
        {t('Fancy Face account')}
      </h2>
      <p className="italic text-progressBlock text-center font-medium">
        {t('Create an account to track your progress')}
      </p>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full flex flex-col shrink grow basis-auto mt-12"
      >
        {errors.name ? (
          <p className="font-normal italic ml-6 mt-6 mb-2 text-xs2 text-secondary text-left">
            {errors.name.message}
          </p>
        ) : (
          <label htmlFor="your-name" className={labelClasses}>
            {t('Name')}
          </label>
        )}

        <input
          className={`${errors.name ? 'border-mark' : ''} ${inputClasses}`}
          {...register('name')}
          id="your-name"
          onChange={(e) => {
            handleInputChange('name', e.target.value)
          }}
          value={name}
          placeholder={t('Your name')}
        />

        {errors.email ? (
          <p className="font-normal italic ml-6 mt-6 mb-2 text-xs2 text-secondary text-left">
            {errors.email.message}
          </p>
        ) : (
          <label htmlFor="your-email" className={labelClasses}>
            {t('Email')}
          </label>
        )}

        <input
          className={`${errors.email ? 'border-mark' : ''} ${inputClasses}`}
          {...register('email')}
          id="your-email"
          value={userEmail}
          onChange={(e) => {
            handleInputChange('email', e.target.value)
          }}
          placeholder={t('beauty1@gmail.com')}
        />

        {errors.password ? (
          <p className="font-normal italic ml-6 mt-6 mb-2 text-xs2 text-secondary text-left">
            {errors.password.message}
          </p>
        ) : (
          <label htmlFor="your-password" className={labelClasses}>
            {t('Password')}
          </label>
        )}
        <input
          className={`${errors.password ? 'border-mark' : ''} ${inputClasses}`}
          {...register('password')}
          id="your-password"
          placeholder={t('beauty111')}
          onChange={(e) => {
            handleInputChange('password', e.target.value)
          }}
          value={password}
        />

        <div className={'text-mark block mt-1 text-xs font-normal text-left'}>{registerErrors}</div>
        <div className="shrink grow basis-auto mt-8 flex items-start">
          <Button
            type="submit"
            className="disabled:bg-primary-blue-300 disabled:border-neutral-500 disabled:text-neutral-500 btn-large uppercase text-questionName font-semibold border-dark-100 bg-primary-blue-400"
            disabled={
              errors?.email !== undefined ||
              errors?.name !== undefined ||
              errors?.password !== undefined ||
              password === ''
            }
          >
            {t('continue with email')}
          </Button>
        </div>
      </form>
    </div>
  )
}
