export const preCreateAccount = async (
  dataObj: object,
  method: string,
): Promise<{ status: number; body: any }> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_MODE === 'dev' ? process.env.REACT_APP_BACKEND_URL_DEV : process.env.REACT_APP_BACKEND_URL}/api/v1/account/web/pre-create`,
      {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataObj),
      },
    )
    let data = {}
    if (method === 'POST') {
      data = await response.json()
    }
    return { status: response.status, body: data }
  } catch (e) {
    return { status: 412, body: { error: e } }
  }
}
