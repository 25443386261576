import React from 'react'
import { Button } from '@/components/Button/Button'
import chart from '@/assets/images/chart-and-text.png'
import { useTranslation } from 'react-i18next'
import { trackDiscount } from '@/helpers/facebookPixelEvents'

interface Percent45OverlayProps {
  onCloseOverlay: () => void
  isShow: boolean
}

export default function Percent45Overlay(props: Percent45OverlayProps) {
  const { t } = useTranslation()
  const handleClick = () => {
    localStorage.setItem('shouldShowPercent45Overlay', 'false')
    trackDiscount('discount_45')
    props.onCloseOverlay()
  }
  return (
    <div className="fixed top-0 left-1/2 -translate-x-1/2 h-full px-2 max-w-content w-full flex items-center z-10">
      <div
        className={`${props.isShow ? 'opacity-50' : 'opacity-0'} fader absolute top-0 left-0 w-full h-full bg-black transition-all duration-500`}
        onClick={handleClick}
      ></div>
      <div
        className={`${props.isShow ? 'active' : ''} show-popup bg-background z-10 relative p-4 pb-6 rounded-3xl transition-all duration-500 `}
      >
        <h2 className="pt-0 mb-2 text-xl3">{t('Have you heard?')}</h2>
        <p className="text-secondary font-normal text-progressBlock">
          <strong className="font-extrabold">68%</strong> {t('overlay45part1')}
          <strong className="font-extrabold"> {t('overlay45part2')} </strong>
          {t('overlay45part3')}
          <strong className="font-extrabold"> {t('overlay45part4')} </strong>
        </p>
        <img className="min-w-image2 mt-4 -ml-4" src={chart} alt="Chart image" />
        <p className="mt-10 text-md1 font-normal">
          {t('To ensure your success, we are delighted to provide an')}
          <strong> {t('exclusive discount')} </strong>
          {t('on your')}
          <strong> {t('Personal Plan')}</strong>.
        </p>
        <div className=" items-end mt-20 mx-4 ">
          <Button
            onClick={handleClick}
            className="btn-large bg-primary-blue-400 text-progressBlock font-semibold"
          >
            {t('Ok, Got It')}
          </Button>
        </div>
      </div>
    </div>
  )
}
