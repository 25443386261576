import { Answer } from '@/types/types'
import React, { useEffect, useState } from 'react'

interface BottleGridProps {
  name: string
  onAnswer: (answer: Answer, step?: number) => void
}

export default function BottleGrid(props: BottleGridProps) {
  // count - for case if wee need this value for example to calculate liters
  const [count, setCount] = useState<number | undefined>(undefined)
  const [gridState, setGridState] = useState(new Array(12).fill(false))

  const updateGridState = (index: number): void => {
    setGridState((prevState) => prevState.map((value, i) => i <= index))
    setCount(index + 1)
  }

  useEffect(() => {
    count && props.onAnswer({ [props.name]: [count.toString()] })
  }, [count])

  return (
    <div className="px-[38px] pb-4">
      <div className="w-full flex flex-row flex-wrap justify-between gap-4">
        {gridState.map((item, index) => (
          <div
            key={index}
            className="w-[60px] h-[60px] flex items-center justify-center"
            onClick={() => updateGridState(index)}
          >
            <img src={`/img/${item ? 'full' : 'empty'}-bottle.svg`} className="object-contain" />
          </div>
        ))}
      </div>
    </div>
  )
}
