import React, { useEffect, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import CheckoutForm from '@/components/StripeCheckout/CheckoutForm'
import { Answers, PaymentPlan, PaywallContent, Plan, Subscription } from '@/types/types'
import { formatPlan } from '@/helpers/formatPlan'
import {
  checkExistSubscription,
  checkIfCustomerExist,
  checkPaymentIntent,
  createScheduleSubscriptionForCustomer,
  createStripeCustomer,
  createSubscriptionForCustomer,
  deleteSubscription,
} from '@/helpers/stripeHelpers'
import { getValue } from '@/helpers/getObjectValue'
import { Button } from '@/components/Button/Button'
import { useNavigate } from 'react-router-dom'
import Notification from '@/components/Notification/Notification'
import { setSuccessPayment } from '@/helpers/setSuccessPayment'
import { useTranslation } from 'react-i18next'

interface StripeCheckoutProps {
  plan: Plan
  paywallContent: PaywallContent
  searchParams?: URLSearchParams
  handleIsLoading: (loading: boolean) => void
}

const stripePublishKey = process.env.REACT_APP_STRIPE_PUBLISH_KEY ?? ''
const stripePromise = loadStripe(stripePublishKey)

export default function StripeCheckout(props: StripeCheckoutProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const storedData = localStorage.getItem('quizResult')
  const answers: Answers = storedData ? JSON.parse(storedData) : false
  const [clientSecretKey, setClientSecretKey] = useState('')
  const [error, setError] = useState('')
  const [customerHasSubscription, setCustomerHasSubscription] = useState(false)
  const [isDiscount45] = useState(localStorage.getItem('isDiscount45') === 'true')
  const [plan] = useState<PaymentPlan | undefined>(
    props.plan ? formatPlan(props.plan, isDiscount45) : undefined,
  )
  const [isPaymentFailed, setIsPaymentFailed] = useState(false)
  const userEmail = getValue('email', answers) as string
  const userName = (getValue('your-name', answers) as string) ?? ('Without name' as string)
  const customerPortalID = localStorage.getItem('customerPortalId') as string
  const customer = { name: userName, email: userEmail, customerPortalID }
  const subscriptionString = localStorage.getItem('subscriptionInfo')
  const [subscription, setSubscription] = useState<Subscription>(
    subscriptionString && subscriptionString !== ''
      ? JSON.parse(subscriptionString)
      : { id: '', current_period_end: '', current_period_start: '', plan },
  )

  const createSubscription = (userID: string) => {
    if (plan) {
      createSubscriptionForCustomer(userID, plan).then((data) => {
        const subscriptionObj = {
          id: data.id,
          current_period_end: data.current_period_end,
          current_period_start: data.current_period_start,
          plan: data.plan.id,
        }
        setSubscription(subscriptionObj)
        localStorage.setItem('subscriptionInfo', JSON.stringify(subscriptionObj))
        setClientSecretKey(data.latest_invoice.payment_intent.client_secret)
      })
    }
  }

  useEffect(() => {
    if (!clientSecretKey) {
      checkIfCustomerExist(customer).then((data) => {
        if (data.data.length) {
          const userID = data.data[0].id
          checkExistSubscription(userID).then((data) => {
            const subscriptions = data.data
            if (subscriptions.length) {
              if (subscriptions.filter((el: any) => el?.status === 'active') <= 0) {
                deleteSubscription(subscriptions[0].id).then(() => {
                  createSubscription(userID)
                })
              } else {
                setCustomerHasSubscription(true)
                props.handleIsLoading(false)
              }
            } else {
              createSubscription(userID)
            }
          })
        } else {
          createStripeCustomer(customer, customerPortalID, userEmail).then((data) => {
            createSubscription(data.id)
          })
        }
      })
    }
  }, [clientSecretKey])

  useEffect(() => {
    if (props.searchParams) {
      const paymentIntent = props.searchParams.get('payment_intent')
      if (paymentIntent) {
        checkPaymentIntent(paymentIntent).then((data) => {
          if (data.status === 'succeeded') {
            if (props.plan.nextProductStripeID && plan) {
              createScheduleSubscriptionForCustomer(subscription, plan).then(() => {
                setSuccessPayment(data, navigate)
              })
            } else {
              setSuccessPayment(data, navigate)
            }
          } else {
            setIsPaymentFailed(true)
          }
        })
      }
    }
  }, [])

  const handleError = (error: string) => {
    setIsPaymentFailed(true)
    setError(error)
  }

  const handleResetNotification = () => {
    setIsPaymentFailed(false)
  }

  return (
    <>
      {clientSecretKey && subscription.id && plan && !customerHasSubscription && (
        <Elements stripe={stripePromise} options={{ clientSecret: clientSecretKey }}>
          <CheckoutForm
            handleError={handleError}
            handleIsLoading={(loading: boolean) => props.handleIsLoading(loading)}
            plan={plan}
            paywallContent={props.paywallContent}
            subscription={subscription}
            navigate={navigate}
          />
          <Notification
            state={isPaymentFailed}
            handleResetNotification={handleResetNotification}
            className="error fixed bottom-6 max-w-content w-full px-4 left-1/2 -translate-x-1/2 transition-al duration-500"
          >
            <span className="w-full py-4 px-6 text-center flex items-center justify-center bg-mark text-white rounded-xl">
              {error || t('Payment was failed')}
            </span>
          </Notification>
        </Elements>
      )}
      {customerHasSubscription && (
        <div className="w-full">
          <h2>{t('You already have a subscription')}</h2>
          <Button onClick={() => setSuccessPayment({}, navigate)}>{t('Create account')}</Button>
        </div>
      )}
    </>
  )
}
