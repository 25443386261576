import React, { useEffect, useState } from 'react'
import { Answer, ImageRadioButtonType, QuizVariantType } from '@/types/types'

interface ImageRadioButtonProps {
  attributes: ImageRadioButtonType
  onAnswer: (answer: Answer) => void
  triggerNextStep: () => void
  name: string
  answer: any
  quizVariant: QuizVariantType
}

export default function ImageRadioButton(props: ImageRadioButtonProps) {
  const [checked, setChecked] = useState(false)
  const handleChange = () => {
    if (!checked) {
      setChecked(true)
      props.onAnswer({
        [props.name]: [props.attributes.alt],
      })
      setTimeout(() => {
        props.triggerNextStep()
      }, 300)
    }
  }
  useEffect(() => {
    if (
      props.answer.find((el: any) => props.name in el)?.[props.name][0] === props.attributes.alt
    ) {
      setChecked(true)
    } else {
      setChecked(false)
    }
  }, [props])

  useEffect(() => {
    if (props.quizVariant === 'web2' && props.attributes.selectedImgSrc) {
      const img = new Image()
      img.src = props.attributes.selectedImgSrc
    }
  }, [props.quizVariant])

  return (
    <div>
      <label
        className={`${checked ? ' border !bg-[#fcfcfc] border-borderChecked' : ' border border-transparent '} font-inputWeight transition-all duration-300 border-border  mb-2 mt-0  rounded-lg ${props.quizVariant === 'default' ? 'px-6 py-4' : ' justify-center pt-2 pb-1 '} cursor-pointer flex items-center bg-input`}
      >
        <div className="flex flex-col justify-center items-center">
          {props.quizVariant === 'web2' && props.attributes.selectedImgSrc ? (
            <img
              src={checked ? props.attributes.selectedImgSrc : props.attributes.imgSrc}
              alt={props.attributes.alt}
              className={
                props.quizVariant === 'web2'
                  ? 'w-[68px] h-[88px] max-mini:w-[58px] transition-all max-mini:h-[78px] object-contain'
                  : ''
              }
            />
          ) : (
            <img src={props.attributes.imgSrc} alt={props.attributes.alt} />
          )}
          {props.quizVariant === 'web2' && (
            <p className="text-xl leading-[25.6px] max-mini:text-lg max-mini:leading-6 font-medium">
              {props.attributes.alt}
            </p>
          )}
        </div>
        <input
          type="radio"
          onChange={handleChange}
          name={props.name}
          value={props.attributes.alt}
          className="hidden"
          checked={checked}
        />
      </label>
    </div>
  )
}
