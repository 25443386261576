import { Progress } from '@/components/Progress/Progress'
import React from 'react'
import { Question, QuizVariantType } from '@/types/types'
import { useTranslation } from 'react-i18next'

interface ProgressQuizProps {
  question: Question
  quizVariant: QuizVariantType
  progressQuestions: Array<Question[]>
  step: number
}

export const ProgressQuiz = (props: ProgressQuizProps) => {
  const { t } = useTranslation()

  const progressQuestions = props.progressQuestions

  const getCurrentQuestionIndex = (questions: Question[][], currentQuestion: Question) => {
    for (let i = 0; i < questions.length; i++) {
      const chapters = questions[i].filter((q) => !q.excludeFromProgress)

      const currentChapter = i
      const currentQuestionIndex = chapters.findIndex(
        (q) => JSON.stringify(q) === JSON.stringify(currentQuestion),
      )

      if (currentQuestionIndex !== -1) {
        return {
          currentChapter,
          currentQuestionIndex: currentQuestionIndex === 0 ? 1 : currentQuestionIndex + 1,
        }
      }
    }

    return { currentChapter: questions.length - 1, currentQuestionIndex: 1 }
  }

  const { currentChapter, currentQuestionIndex } = getCurrentQuestionIndex(
    progressQuestions,
    props.question,
  )

  if (props.question.hideProgress === true || props.question.excludeFromProgress === true) {
    return null
  }

  return (
    <div className="flex flex-col items-center relative w-full px-4">
      {props.quizVariant === 'default' && (
        <div className="mt-4 text-center text-xs2 italic container font-normal relative z-1">
          {t('Question: ')}
          {props.progressQuestions.findIndex(
            (q) => JSON.stringify(q) === JSON.stringify(props.question),
          ) + 1}
          {t(' of ')} {props.progressQuestions.length}
        </div>
      )}
      <div className="w-full flex flex-row justify-between">
        {progressQuestions.map((chapter, index) => {
          const chapterWithoutProgress = chapter.filter((q) => !q.excludeFromProgress)
          const isActiveChapter = currentChapter === index
          const progressValue = isActiveChapter
            ? (currentQuestionIndex / chapterWithoutProgress.length) * 100
            : currentChapter > index
              ? 100
              : 0

          const progressCount = props.question.simpleProgress ? 1 : progressQuestions.length - 1

          if (
            (props.question.simpleProgress && index > 0) ||
            (!props.question.simpleProgress && index === 0)
          ) {
            return null
          }

          return (
            <div key={index} className="relative" style={{ width: `${100 / progressCount - 1}%` }}>
              <Progress
                value={progressValue}
                className={props.quizVariant === 'web2' ? 'h-[2px] mt-5 mb-1' : 'h-1'}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}
