import React, { useEffect, useState } from 'react'
import { PaywallContent, Plan } from '@/types/types'
import { formatDollarsAndCents /* , formattedPrice */ } from '@/helpers/formatPrice'
import { useNavigate } from 'react-router-dom'
// import Lottie from 'lottie-react'
// import animationData from '../../assets/images/button_get_my_plan.json'
import { useTranslation } from 'react-i18next'
import Timer from './Timer'
import { trackDefaultPaymentLoaded } from '@/helpers/facebookPixelEvents'
import StripeApplePay from '@/components/StripeCheckout/StripeApplePay'
import Preloader from '@/components/ui/Preloader'

interface PlansProps {
  privacyText: string
  paywallContent: PaywallContent
  paymentMethod: string
  isDiscount45: boolean
  activePlan: number
  handlePlan: (index: number) => void
  plans: Plan[]
  handleExpiredTimer: () => void
  handleSelectPlan: () => void
}

export function Plans(props: PlansProps) {
  const { t } = useTranslation()
  const withApplePay = localStorage.getItem('isPaywallWithApplePay') === 'true'
  const [availableApplePay, setAvailableApplePay] = useState(withApplePay)
  const [isLoading, setIsLoading] = useState(withApplePay)
  const navigate = useNavigate()
  const percentsText = props.isDiscount45
    ? props.paywallContent.percentText45
    : props.paywallContent.percentText30

  const handleSetLoading = (val: boolean) => {
    setIsLoading(val)
  }
  const handleSetAvailableApplePay = (val: boolean) => {
    if (!val) {
      setIsLoading(true)
      setTimeout(() => {
        setIsLoading(false)
      }, 700)
    }
    setAvailableApplePay(val)
  }

  useEffect(() => {
    localStorage.setItem('activePlan', JSON.stringify(props.plans[props.activePlan]))
    if (!withApplePay) {
      trackDefaultPaymentLoaded()
    }
  }, [])
  const handleCheckout = (): void => {
    localStorage.setItem('activePlan', JSON.stringify(props.plans[props.activePlan]))
    navigate('/checkout', {
      state: { paymentMethod: props.paymentMethod, paywallContent: props.paywallContent },
    })
  }

  return (
    <div>
      <div className="flex flex-row px-2 justify-between bg-neutral-100 pb-6">
        {props.plans.map((el, index) => {
          const perDayPrice = props.isDiscount45
            ? formatDollarsAndCents(el.pricePerDay45)
            : formatDollarsAndCents(el.pricePerDay30)
          const perMounthPrice = props.isDiscount45
            ? formatDollarsAndCents(el.discountedPrice45)
            : formatDollarsAndCents(el.discountedPrice30)
          // const price = props.isDiscount45 ? el.discountedPrice45 : el.discountedPrice30
          return (
            <div
              key={el.name}
              onClick={() => {
                props.handlePlan(index)
              }}
              className={`${!el.mostPopular && index === props.activePlan ? 'bg-white' : ''} ${index === props.activePlan ? 'active bg-btn border-secondary text-dark-200 ' : 'text-gray border-neutral-300 bg-neutral-200 '} w-[32%] relative mt-3 border rounded-[8px] cursor-pointer transition-all`}
            >
              <div
                className={`${index === props.activePlan ? ' bg-paywallGradient border-secondary ' : ' bg-neutral-200 border-neutral-300 '} absolute w-20 h-6 border rounded-[8px] top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 z-1 text-center`}
              >
                -{percentsText}
              </div>
              {el.mostPopular && (
                <div className="bg-greenGradient absolute px-4 py-1 rounded-2xl text-white font-semibold text-md left-6 top-0 -translate-y-1/2">
                  {t('MOST POPULAR')}
                </div>
              )}
              <div className="text-center pt-6 pb-4">
                <h3 className="italic font-normal text-questionName mb-2">{el.name}</h3>
                <div className="flex flex-col">
                  <p className="text-dark-100 line-through text-md1 font-normal">
                    ${el.pricePerDay}
                  </p>
                  <p className="text-dark-200 text-questionName font-bold">
                    ${perDayPrice[0]}.{perDayPrice[1]}
                  </p>
                  <p className="font-normal text-md1 ">{t('per day')}</p>
                  <p className="text-xs2 font-normal italic mt-2">
                    ${perMounthPrice[0]}.{perMounthPrice[1]} / {el.perWhat}
                  </p>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <div className="py-2 px-4 relative bg-paywallGradient w-full text-center text-dark-200 flex flex-col items-center gap-y-2">
        <Timer
          paywallContent={props.paywallContent}
          isDiscount45={props.isDiscount45}
          onTimerExpired={props.handleExpiredTimer}
        />
        {isLoading && <Preloader />}
        {availableApplePay && (
          <>
            {/* <StartPlanButton */}
            {/*  handleClick={() => navigate('/refund-policy/')} */}
            {/*  buttonText={t('30-day money back guarantee')} */}
            {/*  style="secondary" */}
            {/* /> */}
            <StripeApplePay
              onAvailableApplePay={handleSetAvailableApplePay}
              onLoading={handleSetLoading}
              plan={props.plans[props.activePlan]}
              isDiscount45={props.isDiscount45}
              handleSelectPlan={props.handleSelectPlan}
            />
            <button
              onClick={handleCheckout}
              className="cursor-pointer relative rounded-button py-2"
            >
              <span className="underline w-full bg-transparent text-primary text-base font-medium cursor-pointer flex justify-center items-center min-w-40">
                Continue with other payment method
              </span>
            </button>
          </>
        )}
        {!availableApplePay && (
          <div
            onClick={handleCheckout}
            className="cursor-pointer relative w-8/12 button-shadow bg-white border rounded-button py-2"
          >
            <div className="uppercase font-semibold text-questionName">{t('Get my plan')}</div>
            <div className="absolute top-[1px] right-4 translate-y-1/2 ">
              <img src="/img/right-arrow.svg" className="animate-right-bounce" alt="" />
            </div>
          </div>
        )}
      </div>
      <p
        className="mt-2 text-left text-xs px-4 font-light text-black link-decoration"
        dangerouslySetInnerHTML={{ __html: props.privacyText }}
      />
    </div>
  )
}
