import React from 'react'
import { useTranslation } from 'react-i18next'

interface TimerOverlay {
  onCloseOverlay: () => void
  isShow: boolean
}

export default function TimerOverlay(props: TimerOverlay) {
  const { t } = useTranslation()
  const handleClick = () => {
    localStorage.setItem('shouldShowOverlayExpired', 'false')
    props.onCloseOverlay()
  }
  return (
    <div className="fixed top-0 left-1/2 -translate-x-1/2 h-full max-w-content w-full flex items-end z-[10]">
      <div
        className={`${props.isShow ? 'opacity-50' : 'opacity-0'} fader absolute z-[11] top-0 left-0 w-full h-full bg-black transition-all duration-500`}
        onClick={handleClick}
      ></div>

      <div className="fixed top-0 left-1/2 -translate-x-1/2 h-full px-2 max-w-content backdrop-blur-sm z-[12] w-full flex items-center">
        <div className="w-full bg-primary-blue-100 p-4 rounded-2xl">
          <div className="flex flex-col gap-y-2">
            <div className="font-semibold text-questionStartName text-center">
              {t('Ooops, timer is over...')} <br /> {t('but we saved your discount')}
            </div>
            <div className="italic text-progressBlock text-secondary font-normal text-center">
              {t('Welcome to the world of beauty.')}
              <br />
              {t('Choose a Personal Plan and Start Today')}
            </div>
          </div>
          <div className="mx-8 mt-8">
            <img src={'/img/overlay-timer-over.png'} alt="" />
          </div>
          <div className="px-4">
            <button
              onClick={handleClick}
              className="uppercase mb-4 w-full text-center py-2 border border-dark-100 rounded-button bg-primary-blue-400 text-questionName font-semibold button-shadow"
            >
              {t('Wow, thank you')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
