import { PreliminaryProcessingQuestionInterface, RadioButtonType } from '@/types/types'
import React from 'react'

interface ProgressQuestionProps {
  handleAnswer: (answer: RadioButtonType) => void
  question: PreliminaryProcessingQuestionInterface
}

const ProgressQuestion = (props: ProgressQuestionProps) => {
  const { question, handleAnswer } = props
  return (
    <div className="fixed top-0 left-0 h-full z-40 w-full bg-[rgba(0,0,0,.32)] transition-all duration-300 flex justify-center items-center">
      <div className="min-w-[300px] max-w-[658.5px] p-7 question-border-web2 mx-2 bg-neutral-100 rounded-3xl border border-web2-border relative z-50 flex flex-col gap-y-4">
        <h2 className="text-questionName max-mini:text-lg max-mini:leading-6 text-center font-semibold text-primary p-0 m-0">
          {question.name}
        </h2>
        <div className="w-full flex flex-col gap-y-1">
          {props.question.components.map((answerButton, index) => (
            <button
              onClick={() => handleAnswer(answerButton.radioButton)}
              key={index}
              className="flex flex-row justify-start items-center gap-x-2 px-4 py-[11px] border border-web2-border rounded-[36px] [&>p]:text-questionName [&>p]:max-mini:text-lg [&>p]:max-mini:leading-6 [&>p]:font-medium"
            >
              <p>{answerButton.radioButton.textIcon ?? ''}</p>
              <p>{answerButton.radioButton.text}</p>
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ProgressQuestion
