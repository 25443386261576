import { Answer, PeopleOnPlanetInterface } from '@/types/types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface PeopleOnPlanetProps {
  peopleDots: {
    planetImg: string
    planetImgWebP: string
    dots: Array<PeopleOnPlanetInterface>
  }
  active: boolean
  onAnswer: (answer: Answer, step?: number) => void
  name: string
}

const PeopleOnPlanet = (props: PeopleOnPlanetProps) => {
  const { t } = useTranslation()
  const [items] = useState(props.peopleDots.dots)
  const [currentItemIndex, setCurrentItemIndex] = useState(0)
  const [displayedItems, setDisplayedItems] = useState<Array<PeopleOnPlanetInterface>>([])

  useEffect(() => {
    if (props.active) {
      const timeout = setTimeout(() => {
        if (currentItemIndex < items.length) {
          setDisplayedItems((prevItems) => [...prevItems, items[currentItemIndex]])
          setCurrentItemIndex(currentItemIndex + 1)
        }
        if (currentItemIndex === items.length) {
          props.onAnswer({ [props.name]: [t('ok')] })
        }
      }, 400)
      return () => clearTimeout(timeout)
    }
  }, [currentItemIndex, items, props.active])

  return (
    <div className="relative mt-4">
      <picture>
        <source srcSet={props.peopleDots.planetImgWebP} type="image/webp" />
        <img
          src={props.peopleDots.planetImg}
          className="max-w-[calc(100%+50px)] relative left-[-25px]"
          alt={t('planet')}
        />
      </picture>
      {displayedItems.map((item, index) => (
        <div
          key={index}
          className="absolute animate-dot"
          style={{
            top: item.position.top,
            left: item.position.left,
            position: 'absolute',
            width: item.size.width + 'px',
            height: item.size.height + 'px',
          }}
        >
          <picture>
            <source srcSet={item.imgWebP} type="image/webp" />
            <img
              src={item.img}
              alt={item.img.split('.png')[0]}
              style={{
                width: item.size.width + 'px',
                height: item.size.height + 'px',
              }}
            />
          </picture>
        </div>
      ))}
    </div>
  )
}

export default PeopleOnPlanet
