import React from 'react'
import { useTranslation } from 'react-i18next'

interface ScrollToPlanButtonProps {
  handleScrollToPlan: () => void
}

const ScrollToPlanButton = (props: ScrollToPlanButtonProps) => {
  const { t } = useTranslation()
  return (
    <button
      className="border border-dark-100 rounded-button py-2 px-3 w-full mx-8 relative button-shadow bg-primary-blue-400"
      onClick={() => props.handleScrollToPlan()}
    >
      <div className="absolute top-[1px] right-4 translate-y-1/2 ">
        <img src="/img/right-arrow.svg" className="animate-right-bounce" alt="" />
      </div>
      <div className="uppercase font-semibold text-questionName">{t('Get my program')}</div>
    </button>
  )
}

export default ScrollToPlanButton
