import { QuizVariantType } from '@/types/types'
import React, { useEffect, useState } from 'react'

interface AfterQuizAnimationProps {
  attributes: { duration: number; text?: string }
  triggerNextStep: () => void
  curent: boolean
  quizVariant: QuizVariantType
}

const AfterQuizAnimation = (props: AfterQuizAnimationProps) => {
  const [progress, setProgress] = useState(0)
  const handle = () => {
    props.triggerNextStep()
  }
  useEffect(() => {
    const startTimer = async () => {
      const interval = setInterval(() => {
        setProgress((prevProgress) => prevProgress + 10000 / props.attributes.duration)
      }, 100)

      setTimeout(() => {
        clearInterval(interval)
        handle()
      }, props.attributes.duration)

      return () => clearInterval(interval)
    }
    if (props.curent) {
      startTimer()
    }
  }, [props.curent])
  return (
    <>
      <div
        className={`flex flex-col w-full gap-y-2 max-mini:gap-y-1 text-center ${props.quizVariant === 'web2' ? 'mb-24 max-mini:mb-20' : ''}`}
      >
        <p
          className={`font-normal ${props.quizVariant === 'web2' ? 'text-primary-blue-100 text-base max-mini:text-md' : ' italic text-md1 max-mini:text-sm max-mini:leading-4 '}`}
        >
          {props.attributes?.text}
        </p>
        <div
          className={`progress-bar ${props.quizVariant === 'web2' ? ' !border-0 relative !bg-web2secondary-100 ' : ''}`}
        >
          <div
            className={`progress ${props.quizVariant === 'web2' ? 'bg-quiz2progressMain !border-0 !h-6 max-mini:!h-5 !rounded-2xl ' : ''}`}
            style={{ width: `${progress}%` }}
          >
            {props.quizVariant === 'web2' && (
              <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 inline-block font-normal text-md2 max-mini:text-md1 leading-[22px] max-mini:leading-5 text-dark-200">
                {progress.toFixed(0)}%
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default AfterQuizAnimation
