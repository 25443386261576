import { sliderBlockItemType } from '@/types/types'
import React, { useRef } from 'react'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { A11y, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react'
import { reviewPopupType } from '../Paywall/Paywall'
import ScrollToPlanButton from './ScrollToPlanButton'
import { useTranslation } from 'react-i18next'

interface ReviewsSliderProps {
  slides: sliderBlockItemType[]
  note: string
  title: string
  handleOpenReviewPopup: (review: reviewPopupType) => void
  handleScrollToPlan: () => void
}

const ReviewsSlider = (props: ReviewsSliderProps) => {
  const swiperRef = useRef<SwiperRef>(null)
  const { t } = useTranslation()
  const handleOpenPopup = (target: any): void => {
    props.handleOpenReviewPopup(target)
  }
  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext()
    }
  }

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev()
    }
  }
  return (
    <div className="bg-neutral-100 flex flex-col py-4">
      <div>
        <p
          className="paywall__slider__title mx-2 px-2 "
          dangerouslySetInnerHTML={{ __html: props.title }}
        ></p>
      </div>
      <div className="w-full flex flex-row">
        <button onClick={goPrev} className="w-10 h-2/3 mt-5 flex justify-center items-center">
          <img src="/img/paywallSlider/arrow-left.svg" alt="" />
        </button>
        <Swiper
          modules={[Navigation, Pagination, A11y]}
          slidesPerView={1}
          ref={swiperRef}
          loop={true}
          className="w-full my-5"
        >
          <div className="flex flex-col">
            {props.slides.map((slide, index) => (
              <SwiperSlide key={index}>
                <div className="flex flex-col">
                  <div className="flex flex-row justify-evenly">
                    <div className="w-[48.5%] flex flex-col bg-primary-blue-100 border border-dark-200 rounded-[8px] ">
                      <div className="p-1 pb-0">
                        <img
                          src={slide.photos.beforeSrc}
                          className="border border-dark-200 rounded-[4px]"
                          alt={`before ${slide.photos.beforeSrc}`}
                        />
                      </div>
                      <p className="uppercase mt-1 mb-[5px] font-semibold text-md2">
                        {t('before')}
                      </p>
                    </div>
                    <div className="w-[48.5%] flex flex-col bg-primary-blue-100 border border-dark-200 rounded-[8px] ">
                      <div className="p-1 pb-0">
                        <img
                          src={slide.photos.afterSrc}
                          className="border border-dark-200 rounded-[4px]"
                          alt={`before ${slide.photos.afterSrc}`}
                        />
                      </div>
                      <p className="uppercase mt-1 mb-[5px] font-semibold text-md2">{t('after')}</p>
                    </div>
                  </div>
                  <div className="flex justify-center items-center mt-2">
                    <div className="w-full mx-1 border rounded-[8px] py-2 px-3 flex flex-col gap-y-1">
                      <div className="text-left font-semibold text-progressBlock">
                        {slide.review.user}
                      </div>
                      <div className="text-ellipsis overflow-hidden text-md2 line-clamp-3">
                        {slide.review.text}
                      </div>
                      <div className="flex justify-end">
                        <p
                          className="cursor-pointer text-secondary font-semibold text-md1"
                          onClick={() => handleOpenPopup(slide.review)}
                        >
                          {t('read full review')}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </div>
        </Swiper>
        <button
          onClick={goNext}
          className="w-10 h-2/3 mt-5 flex justify-center bg-transparent items-center"
        >
          <img src="/img/paywallSlider/arrow-right.svg" alt="" />
        </button>
      </div>
      <div className="flex w-full justify-center items-center">
        <ScrollToPlanButton handleScrollToPlan={props.handleScrollToPlan} />
      </div>
      <p className="mx-2 px-2 mt-4 font-normal text-xs leading-[14.08px] text-left">{props.note}</p>
    </div>
  )
}

export default ReviewsSlider
