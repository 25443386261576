import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Layout from '@/components/Layout'
import QuestionStep from '@/components/QuestionStep/QuestionStep'
import { Answer, Answers, Question, QuizVariantType } from '@/types/types'
import { useTranslation } from 'react-i18next'
import { getValue } from '@/helpers/getObjectValue'
import { trackEmail, trackPageView, trackSecondPage } from '@/helpers/facebookPixelEvents'
import { getCountryCode } from '@/helpers/getCountryCode'
import { preCreateAccount } from '@/helpers/preCreateAccount'
import { FancyFaceLogo } from '../ui/Icons'
import HeaderBlur from '../ui/HeaderBlur'
import { Helmet } from 'react-helmet'
import { ProgressQuiz } from '@/components/QuestionsUI/ProgressQuiz'
import Cookies from 'cookies-js'

interface QuizProps {
  quizFile: string
  lang: string
}

export default function Quiz(props: QuizProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { currentScreen } = useParams()
  const [questions, setQuestions] = useState<Question[]>([])
  const [mode, setMode] = useState<string>('')
  const [questionsLength, setQuestionsLength] = useState(0)
  const [progressQuestions, setProgressQuestions] = useState<Array<Question[]>>([])
  const [answers, setAnswers] = useState<Answers>({ 0: [] })
  const [paywallUrl, setPaywallUrl] = useState('')
  const [error, setError] = useState('')
  const [progressString, setProgressString] = useState<string>('')
  const [blockName, setBlockName] = useState<string>('')
  const [quizVariant, setQuizVariant] = useState<QuizVariantType>('default')
  const currentQuestion = parseInt(currentScreen ?? '0')

  if (Cookies.get('gotToPaywall') && localStorage.getItem('quizResult')) {
    navigate(paywallUrl)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await import(`../../locales/${props.lang}/${props.quizFile}`)
        const concatenatedArrays = response?.questions && [].concat(...response.questions)
        const emptyAnswers = Array.from({ length: concatenatedArrays?.length }, (_, index) => [
          index,
          [],
        ])
        setAnswers(Object.fromEntries(emptyAnswers))
        setQuestions(concatenatedArrays)
        setProgressQuestions(response?.questions)
        setPaywallUrl(response?.paywall)
        setMode(response?.mode)
        setQuizVariant(response?.variant ?? 'default')
      } catch (error) {
        console.error('Error fetching quiz data:', error)
      }
    }
    fetchData()
    getCountryCode().then((res) => {
      localStorage.setItem('countryCode', res ? res.country : 'US')
      localStorage.setItem('postalCode', res ? res.postal : '90071')
      localStorage.setItem('ip', res ? res.ip : '')
    })
  }, [])

  useEffect(() => {
    setQuestionsLength(questions?.length)
  }, [questions])

  useEffect(() => {
    trackPageView()
    if (currentQuestion === 1) {
      trackSecondPage()
    }
  }, [currentQuestion])

  const isEmptyObject = (obj: any) => {
    return Object.keys(obj).length <= 1 && obj.constructor === Object
  }

  useEffect(() => {
    if (isEmptyObject(answers)) {
      const { pathname } = location
      if (!pathname.includes('/0/')) {
        const nextPathname = location.pathname.replace(`/${currentQuestion}/`, '/0/')
        navigate(nextPathname)
      }
    }
  }, [answers])

  const handleNextQuestion = () => {
    const nextQuestion = currentQuestion + 1
    const skipConditionObject = questions[currentQuestion]?.skipWhenTrue
    if (skipConditionObject) {
      const fieldName = skipConditionObject?.fieldName
      const skipCondition =
        answers[currentQuestion][0][fieldName][0] === skipConditionObject?.answer
      if (fieldName && skipCondition) {
        const nextPathname = location.pathname.replace(
          `/${currentQuestion}/`,
          `/${nextQuestion + skipConditionObject.toSkip}/`,
        )
        navigate(nextPathname)
      } else {
        navigate(location.pathname.replace(`/${currentQuestion}/`, `/${nextQuestion}/`))
      }
    } else {
      const nextPathname = location.pathname.replace(`/${currentQuestion}/`, `/${nextQuestion}/`)
      navigate(nextPathname)
    }
  }

  const handleAnswer = (answer: Answer, step: number = currentQuestion) => {
    setAnswers((prevAnswers) => {
      const newAnswers = { ...prevAnswers }
      const answerKey = Object.keys(answer)[0]
      if (newAnswers[step]) {
        const answerIndex = newAnswers[step].findIndex((item) => {
          return Object.keys(item)[0] === answerKey
        })
        if (answerKey.includes('array') && answerIndex > -1) {
          const currentValue = Object.values(answer)[0][0]
          if (currentValue && !currentValue.includes('remove-')) {
            newAnswers[step][answerIndex][answerKey] = [
              ...newAnswers[step][answerIndex][answerKey],
              ...Object.values(answer)[0],
            ]
          } else {
            newAnswers[step][answerIndex][answerKey].splice(
              newAnswers[step][answerIndex][answerKey].indexOf(currentValue.slice(7)),
              1,
            )
          }
        } else {
          if (answerIndex >= 0) {
            newAnswers[step][answerIndex][answerKey] = answer[answerKey]
          } else {
            newAnswers[step].push(answer)
          }
        }
      } else {
        newAnswers[step].push(answer)
      }
      return newAnswers
    })
  }

  const handleRemoveAnswer = (answer: Answer) => {
    setAnswers((prevAnswers) => {
      const newAnswers = { ...prevAnswers }
      const answerKey = Object.keys(answer)[0]
      const answerIndex = newAnswers[currentQuestion].findIndex((item) => {
        return Object.keys(item)[0] === answerKey
      })
      if (answerIndex >= 0) {
        newAnswers[currentQuestion][answerIndex][answerKey] = []
      }
      return newAnswers
    })
  }

  const handleShowNextButton = () => {
    const hasValues = answers[currentQuestion].length
    if (hasValues) {
      const index = answers[currentQuestion].findIndex((item) => {
        return Object.values(item)[0].length === 0
      })
      return index >= 0
    }
    return !hasValues
  }

  const handleSetPaywallCookie = () => {
    Cookies.set('gotToPaywall', 'true', { expires: 900 })
  }

  const successSubmit = (customerPortalID: string) => {
    handleSetPaywallCookie()
    localStorage.setItem('answers', JSON.stringify(answers))
    localStorage.setItem('customerPortalId', customerPortalID)
    const userEmail = getValue('email', answers) as string
    const endTime = new Date()
    trackEmail('mail_1', userEmail as string)
    endTime.setTime(endTime.getTime() + 5 * 60 * 1000)
    localStorage.setItem('quizResult', JSON.stringify(answers))
    localStorage.setItem('endTimer', endTime.toISOString())
    localStorage.setItem('shouldShowOverlayExpired', 'true')
    localStorage.setItem('shouldShowPercent45Overlay', 'true')
    localStorage.setItem('isDiscount45', 'false')
    navigate(paywallUrl, { replace: true })
  }

  const successSubmitQuiz2 = () => {
    handleSetPaywallCookie()
    localStorage.setItem('answers', JSON.stringify(answers))
    const endTime = new Date()
    endTime.setTime(endTime.getTime() + 5 * 60 * 1000)
    localStorage.setItem('quizResult', JSON.stringify(answers))
    localStorage.setItem('endTimer', endTime.toISOString())
    localStorage.setItem('shouldShowOverlayExpired', 'true')
    localStorage.setItem('shouldShowPercent45Overlay', 'true')
    localStorage.setItem('isDiscount45', 'false')
    navigate(paywallUrl, { replace: true })
  }

  const handleQuizSubmit = () => {
    const userEmail = getValue('email', answers) as string
    const customerPortalId = localStorage.getItem('customerPortalId')
    if (quizVariant === 'default') {
      if (customerPortalId) {
        const data = {
          email: userEmail,
          _id: customerPortalId,
        }
        preCreateAccount(data, 'PATCH').then((res) => {
          if (res.body.error === '"email" must be a valid email') {
            setError('Email is not valid')
          } else {
            successSubmit(customerPortalId)
          }
        })
      } else {
        const data = { email: userEmail }
        preCreateAccount(data, 'POST').then((res) => {
          if (res.body.id) {
            successSubmit(res.body.id)
          } else {
            if (res.body.error === '"email" must be a valid email') {
              setError('Email is not valid')
            }
          }
        })
      }
    } else if (quizVariant === 'web2') {
      successSubmitQuiz2()
    }
  }

  const handleSkipNextQuestions = (count: number) => {
    const nextPathname = location.pathname.replace(
      `/${currentQuestion}/`,
      `/${currentQuestion + count + 1}/`,
    )
    navigate(nextPathname)
  }

  const preloadNextImages = () => {
    const indexes = [currentQuestion + 1, currentQuestion + 2]
    indexes.forEach((el) => {
      if (questions[el]?.bg) {
        if (questions[el].bg?.bgImgWebP) {
          const img = new Image()
          img.src = questions[el].bg?.bgImgWebP ?? ''
        }
      }
    })
  }

  useEffect(() => {
    preloadNextImages()
  }, [currentQuestion, questions])

  return (
    <Layout className={mode}>
      {currentQuestion >= 2 && (
        <Helmet>
          <link
            rel="preload"
            href="/img/lottie/chart-quiz-09.json"
            as="fetch"
            crossOrigin="anonymous"
            type="application/json"
          />
          <link
            rel="preload"
            href="/img/lottie/chart-quiz-14.json"
            as="fetch"
            crossOrigin="anonymous"
            type="application/json"
          />
          <link
            rel="preload"
            href="/img/lottie/chart-quiz-18.json"
            as="fetch"
            crossOrigin="anonymous"
            type="application/json"
          />
          <link rel="preload" href="/img/before-wrinkles.webp" as="image" />
          <link rel="preload" href="/img/after-wrinkles.webp" as="image" />
        </Helmet>
      )}

      <div className="absolute top-0 left-0 w-full h-full z-0">
        {questions && questions[currentQuestion]?.bg && (
          <div
            key={currentQuestion}
            className={`w-full h-full z-0 ${questions[currentQuestion].bg ? 'bg-image-animation' : ''} relative`}
          >
            {questions[currentQuestion]?.bg?.bgImg && (
              <>
                <picture>
                  <source srcSet={questions[currentQuestion]?.bg?.bgImgWebP} type="image/webp" />
                  <img
                    className={`object-cover w-full h-full ${questions[currentQuestion]?.bg?.position && ' absolute '}`}
                    src={questions[currentQuestion]?.bg?.bgImg}
                    alt="fancy-face-bg"
                    style={{
                      top: questions[currentQuestion]?.bg?.position?.top,
                      left: questions[currentQuestion]?.bg?.position?.left,
                    }}
                  />
                </picture>
              </>
            )}
          </div>
        )}

        {!questions[currentQuestion]?.hideGradientBg && (
          <HeaderBlur className="!absolute !z-10 top-[-14%]" />
        )}
      </div>

      {questions[currentQuestion]?.bg?.gradient && (
        <div className="absolute top-0 left-0 w-full h-full">
          <img
            className="object-cover w-full h-full"
            src={questions[currentQuestion].bg?.gradient?.img}
            alt="gradient"
          />
        </div>
      )}

      {questions[currentQuestion]?.privacyPolicy && (
        <div className="absolute font-inter text-center bottom-0 left-0 w-full flex flex-col justify-end items-center pb-1 gap-4 z-50">
          <div>
            <p
              className="privacyPolicy text-white font-normal text-xxs leading-[15px]"
              dangerouslySetInnerHTML={{
                __html: questions[currentQuestion]?.privacyPolicy?.text as string,
              }}
            ></p>
          </div>
          {questions[currentQuestion]?.note && (
            <div className="max-w-64">
              <p className="font-normal text-xxs text-neutral-400">
                {questions[currentQuestion]?.note?.text}
              </p>
            </div>
          )}
        </div>
      )}

      {quizVariant === 'default' ? (
        <div className="flex items-center justify-center container relative z-1">
          <h1 className="pt-5 pb-2 text-questionName font-semibold text-center">
            {t('Fancy Face - Face Yoga')}
          </h1>
        </div>
      ) : (
        <div className="flex flex-row w-full px-4 items-end justify-between relative mt-[16.5px] z-1">
          <div className="flex flex-row items-end gap-x-[6px]">
            <FancyFaceLogo color={questions?.[currentQuestion]?.logoColor?.svgColor ?? '#CD037B'} />
            <div
              className="flex flex-col items-start"
              style={{
                color: questions?.[currentQuestion]?.logoColor?.textColor ?? '#22223B',
              }}
            >
              <h1 className="text-questionName max-mini:text-lg text-nowrap font-semibold">
                {t('Fancy Face')}
              </h1>
              <p className="text-sm leading-[16.64px] max-mini:text-xs2 font-normal -mt-[3px] italic">
                {t('face yoga')}
              </p>
            </div>
          </div>

          <div className="flex flex-col justify-end items-end">
            {!questions[currentQuestion]?.simpleProgress && progressString && (
              <p className="text-xs2 leading-[15.36px] italic font-normal">{progressString}</p>
            )}
            {blockName && (
              <p className="text-sm leading-none mt-[2px] text-right font-medium">{blockName}</p>
            )}
          </div>
        </div>
      )}

      <div className="relative grow shrink basis-auto flex flex-col">
        {questions[currentQuestion] && quizVariant === 'web2' && (
          <ProgressQuiz
            question={questions[currentQuestion]}
            progressQuestions={progressQuestions}
            quizVariant={quizVariant}
            step={currentQuestion}
          />
        )}

        {questions?.map((el, index) => {
          if (currentQuestion !== index && currentQuestion + 1 !== index) {
            return null
          }
          return (
            <QuestionStep
              handleRemoveAnswer={handleRemoveAnswer}
              onAnswer={handleAnswer}
              onSubmit={handleQuizSubmit}
              onNextClick={handleNextQuestion}
              onShowNextButton={handleShowNextButton}
              key={index}
              active={currentQuestion === index}
              step={index}
              error={error}
              setError={setError}
              answers={answers}
              question={el}
              questionsLength={questionsLength}
              progressQuestions={progressQuestions}
              quizVariant={quizVariant}
              setBlockName={setBlockName}
              setProgressString={setProgressString}
              blockName={blockName}
              progressString={progressString}
              onSkipClick={handleSkipNextQuestions}
            />
          )
        })}
      </div>
    </Layout>
  )
}
