import React, { useEffect, useState } from 'react'
import ProgressQuestion from './ProgressQuestion'
import {
  Answer,
  ImageTitleInterface,
  PreliminaryProcessingQuestionInterface,
  RadioButtonType,
} from '@/types/types'

interface ProgressBarsProps {
  bars: ImageTitleInterface[]
  active: boolean
  question: PreliminaryProcessingQuestionInterface
  onAnswer: (answer: Answer, step?: number) => void
  triggerNextStep: () => void
  name: string
}

const ProgressBars = (props: ProgressBarsProps) => {
  const [progressBars, setProgressBars] = useState(
    props.bars.map((progressValue) => ({
      name: progressValue.title,
      image: progressValue.img,
      progress: 0,
    })),
  )
  const [questionAnswer, setQuestionAnswer] = useState<RadioButtonType | undefined>(undefined)

  const handleAnswer = (answer: RadioButtonType) => {
    setQuestionAnswer(answer)
    setPaused(false)
  }

  const handleNextStep = () => {
    questionAnswer !== undefined &&
      props.onAnswer({
        [props.name]: [
          questionAnswer.text + (questionAnswer.img ? 'img:' + questionAnswer.img : ''),
        ],
      })
    props.triggerNextStep()
  }

  const [paused, setPaused] = useState(false)

  const [allBarsFilled, setAllBarsFilled] = useState<boolean>(false)

  useEffect(() => {
    if (allBarsFilled) {
      handleNextStep()
    }
  }, [allBarsFilled])

  useEffect(() => {
    let interval: string | number | NodeJS.Timeout | undefined
    if (props.active) {
      if (!allBarsFilled && !paused) {
        interval = setInterval(() => {
          setProgressBars((prevProgressBars) => {
            const updatedProgressBars = [...prevProgressBars]
            const currentProgressBarIndex = updatedProgressBars.findIndex(
              (bar) => bar.progress < 100,
            )

            if (currentProgressBarIndex !== -1) {
              updatedProgressBars[currentProgressBarIndex].progress += 1
              if (
                currentProgressBarIndex === 1 &&
                updatedProgressBars[currentProgressBarIndex].progress === 99
              ) {
                setPaused(true)
              }
            }

            const allFilled = updatedProgressBars.every((bar) => bar.progress === 100)
            if (allFilled) {
              setAllBarsFilled(true)
              clearInterval(interval)
            }

            return updatedProgressBars
          })
        }, 50)
      }
      return () => clearInterval(interval)
    }
  }, [props.active, paused, allBarsFilled])
  return (
    <>
      <div className="flex flex-col gap-y-3 max-mini:gap-y-2 mt-6 max-mini:mt-4 mb-10 max-mini:mb-8">
        {progressBars.map((progressBar, index) => (
          <div key={index} className="flex flex-col">
            <div className="flex flex-row justify-between items-center">
              <p className="text-left text-progressBlock max-mini:text-md text-dark-200 font-medium mb-1 max-mini:mb-0.5 transition-all ease-linear">
                {progressBar.name}
              </p>
              <p className="text-right text-progressBlock max-mini:text-md text-dark-200 font-medium">
                {progressBar.progress}%
              </p>
            </div>
            <div className="progress-bar !border-progressBorder !bg-web2secondary-100">
              <div
                className="progress rounded-[8px]"
                style={{
                  width: `${progressBar.progress}%`,
                  backgroundImage: progressBar.image,
                }}
              ></div>
            </div>
          </div>
        ))}
      </div>
      {paused && <ProgressQuestion handleAnswer={handleAnswer} question={props.question} />}
    </>
  )
}

export default ProgressBars
