import { UserReviewInterface } from '@/types/types'
import React from 'react'
import { Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { LocationDot, UserReviewStar } from '../ui/Icons'
import { useTranslation } from 'react-i18next'

interface ReviewsSliderProps {
  slidesContent: UserReviewInterface[]
  current: boolean
}

const ReviewsSlider = (props: ReviewsSliderProps) => {
  const { t } = useTranslation()
  return props.current ? (
    <Swiper
      autoplay={{ delay: 3000, disableOnInteraction: false }}
      modules={[Autoplay]}
      slidesPerView={1}
      slidesPerGroup={1}
      spaceBetween={50}
      loop={true}
    >
      {props.slidesContent.map((slide, index) => (
        <SwiperSlide key={index}>
          <div className="flex flex-col gap-y-2 p-4 max-mini:p-3 max-mini:gap-y-1 bg-web2secondary-100 rounded-2xl font-inter">
            <div className="flex flex-row items-center gap-x-2">
              <div className="w-11 h-11 max-mini:w-9 max-mini:h-9 rounded-full">
                <img
                  src={slide.avatar}
                  alt={slide.author}
                  className="object-cover w-11 h-11 max-mini:w-9 max-mini:h-9 rounded-full"
                />
              </div>
              <div className="flex flex-col items-start">
                <h3 className="p-0 m-0 text-sm leading-5 max-mini:text-xs2 max-mini:leading-[1.15rem] font-bold">
                  {slide.author}
                </h3>
                <div className="flex flex-row items-center gap-x-[10px] max-mini:gap-x-[0.4rem]">
                  <p className="!text-gray !text-sm max-mini:!text-xs2 max-mini:!leading-[1.15rem] !leading-5 !font-normal">
                    {slide.reviewsCount + ' '} {t('reviewsCount')}
                  </p>
                  <div className="flex flex-row items-center gap-x-1">
                    <LocationDot />
                    <p className="!text-gray !text-sm max-mini:!text-xs2 max-mini:!leading-[1.15rem] !leading-5 !font-normal">
                      {slide.location}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full h-[1px] bg-hrColor"></div>
            <div className="flex flex-col gap-y-4 max-mini:gap-y-3">
              <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row items-center gap-x-[2px]">
                  {Array.from({ length: slide.stars }, (_, index) => (
                    <UserReviewStar key={index} />
                  ))}
                </div>
                <p className="text-gray text-sm leading-5 max-mini:text-xs2 max-mini:leading-[1.15rem]">
                  {slide.date}
                </p>
              </div>
              <div className="flex flex-col gap-y-2 max-mini:gap-y-1.5">
                <h4 className="text-web2-neutral-900 text-lg leading-[21.78px] max-mini:text-progressBlock max-mini:leading-[1.1rem] font-bold">
                  {slide.title}
                </h4>
                <p
                  className="text-web2-neutral-700 text-ol max-mini:text-sm max-mini:leading-5 font-normal text-left"
                  dangerouslySetInnerHTML={{ __html: slide.text }}
                ></p>
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  ) : (
    <></>
  )
}

export default ReviewsSlider
