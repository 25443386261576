import React, { useEffect, useState } from 'react'
import Layout from '@/components/Layout'
import { useNavigate } from 'react-router-dom'
import { Answers, PaywallContent } from '@/types/types'
import { Plans } from '@/components/PaywallUI/Plans'
import GoalImageBlock from '../PaywallUI/GoalImageBlock'
import { getValue } from '@/helpers/getObjectValue'
import ReviewPopup from '../PaywallUI/ReviewPopup'
import ScrollToPlanButton from '../PaywallUI/ScrollToPlanButton'
import ReviewsSlider from '../PaywallUI/ReviewsSlider'
import { useTranslation } from 'react-i18next'
import TimerOverlay from '../PaywallUI/TimerOverlay'
import Percent45Overlay from '../PaywallUI/Percent45Overlay'
import { trackLead, trackLoadedPayload } from '@/helpers/facebookPixelEvents'

interface PaywallProps {
  lang: string
  paywallFile: string
  paymentMethod: string
}

export interface reviewPopupType {
  user: string
  text: string
}

export default function Paywall(props: PaywallProps) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const storedData = localStorage.getItem('quizResult') ?? false
  const answers: Answers = storedData ? JSON.parse(storedData) : false
  const [paywallContent, setPaywallContent] = useState<PaywallContent>()
  const [isDiscount45] = useState(localStorage.getItem('isDiscount45') === 'true')
  const [timerIsExpired, setTimerIsExpired] = useState(false)
  const [activePlan, setActivePlan] = useState(1)
  const [shouldShowOverlayExpired, setShouldShowOverlayExpired] = useState(
    localStorage.getItem('shouldShowOverlayExpired') !== 'false',
  )
  const [shouldShowPercent45Overlay, setShouldShowPercent45Overlay] = useState(
    localStorage.getItem('shouldShowPercent45Overlay') !== 'false',
  )
  const getMotivationString = (motivationStr: string | string[] | null): string => {
    if (motivationStr) {
      if (typeof motivationStr === 'string') {
        return motivationStr
      } else {
        return motivationStr[0]
      }
    } else {
      return t('Use less make up')
    }
  }
  const variantForImage = answers && getValue('your-aim', answers)
  const motivationStr = answers && getValue('radio-button-36', answers)
  const motivation = motivationStr && getMotivationString(motivationStr)
  const customerName = answers && getValue('your-name', answers)
  const [planAnchor, setPlanAnchor] = useState<HTMLDivElement | undefined>(undefined)
  const [selectedReview, setSelectedReview] = useState<reviewPopupType | undefined>(undefined)
  const handleOpenReviewPopup = (review: reviewPopupType) => {
    setSelectedReview(review)
  }

  const handleCloseReviewPopup = () => {
    setSelectedReview(undefined)
    document.body.style.overflowY = 'auto'
    document.body.style.paddingRight = '0px'
  }

  const handleScrollToPlan = () => {
    if (planAnchor) {
      planAnchor.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const handlePlan = (index: number) => {
    setActivePlan(index)
  }

  const handleExpiredTimer = () => {
    setTimerIsExpired(true)
  }

  const handleSelectPlan = () => {
    localStorage.setItem('activePlan', JSON.stringify(paywallContent?.plans.items[activePlan]))
    navigate('/checkout', {
      state: { paymentMethod: props.paymentMethod, paywallContent },
    })
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await import(`../../locales/${props.lang}/${props.paywallFile}`)
        setPaywallContent(response)
      } catch (error) {
        console.error('Error fetching paywall data:', error)
      }
    }
    fetchData()
    trackLead()
    trackLoadedPayload()
  }, [])

  useEffect(() => {
    if (timerIsExpired && shouldShowOverlayExpired) {
      setShouldShowOverlayExpired(true)
    }
  }, [timerIsExpired])

  useEffect(() => {
    if (!answers) {
      navigate('/')
    }
  }, [answers])

  return (
    answers && (
      <Layout>
        {paywallContent && (
          <div className="bg-primary-blue-300 py-2 text-center text-progressBlock font-semibold">
            {t('Fancy Face - Face Yoga')}
          </div>
        )}
        {paywallContent?.goalImageBlock && (
          <GoalImageBlock
            goalImageBlock={paywallContent.goalImageBlock}
            variantForImage={variantForImage as string}
            motivation={motivation as string}
            setPlanAnchor={(y) => setPlanAnchor(y)}
            isDiscount45={isDiscount45}
          />
        )}

        {paywallContent?.greetingBlock && (
          <div className="flex flex-col bg-neutral-100 px-2">
            <div className="flex flex-col">
              <h2 className="mb-2 font-medium italic text-questionStartName">
                {paywallContent.greetingBlock.title[0]}{' '}
                <span className="capitalize">{customerName ?? t('Gorgeous')}</span>
                {paywallContent.greetingBlock.title?.[1]}
              </h2>
              <p
                className="paywall__greetings pb-4"
                dangerouslySetInnerHTML={{
                  __html:
                    paywallContent.greetingBlock.subtext +
                    `${isDiscount45 ? '<br />' + t('with -70% discount now') : ''}`,
                }}
              ></p>
            </div>
            <div className="px-4 pt-2 pb-4">
              <p className="font-normal text-xs2 text-center text-dark-100">
                {paywallContent.greetingBlock?.note}
              </p>
            </div>
          </div>
        )}

        {paywallContent?.plans && (
          <Plans
            activePlan={activePlan}
            paywallContent={paywallContent}
            handlePlan={handlePlan}
            paymentMethod={props.paymentMethod}
            privacyText={paywallContent.plans.privacyText}
            plans={paywallContent?.plans.items}
            isDiscount45={isDiscount45}
            handleExpiredTimer={handleExpiredTimer}
            handleSelectPlan={handleSelectPlan}
          />
        )}

        {paywallContent?.benefitsGrid && (
          <div className="flex flex-col items-center pt-6 px-2 relative">
            <div className="absolute h-full w-1/2 top-0 left-0 -translate-x-1/2 -z-10">
              <div className="absolute bottom-0 left-0 h-1/3 w-full">
                <img
                  className="object-cover w-full h-full scale-[2] z-0"
                  src="/img/Ellipse1.png"
                  alt="start-screen-bg"
                />
              </div>
              <div className="absolute top-1/2 -translate-y-1/2 left-0 h-1/3 w-full scale-[2] z-0">
                <img
                  className="object-cover w-full h-full"
                  src="/img/Ellipse2.png"
                  alt="start-screen-bg"
                />
              </div>
              <div className="absolute top-0 left-0 h-1/3 w-full scale-[2] z-0">
                <img
                  className="object-cover w-full h-full"
                  src="/img/Ellipse3.png"
                  alt="start-screen-bg"
                />
              </div>
            </div>
            <h3
              className="text-center text-xl leading-[30px] mb-4 font-semibold text-dark-200"
              dangerouslySetInnerHTML={{ __html: paywallContent.benefitsGrid?.title }}
            ></h3>
            <div className="flex flex-row flex-wrap justify-between gap-y-2 z-1 relative">
              {paywallContent.benefitsGrid.items.map((item, index) => (
                <div
                  key={index}
                  className={`${(index + 1) % 3 === 0 ? 'w-full' : 'w-[49%]'} ${'border-card-' + index.toString()} flex flex-col bg-transparent border rounded-2xl p-3 gap-y-2  benefits-shadow`}
                >
                  <p className="text-center font-semibold italic text-progressBlock">
                    {item?.title}
                  </p>
                  <p className="text-left text-md2 font-normal">{item?.text}</p>
                </div>
              ))}
            </div>
          </div>
        )}

        {paywallContent?.featuredBlock && (
          <div className="my-6 text-center p-2">
            <h3 className="text-xl text-black font-extrabold">
              {paywallContent?.featuredBlock.title}
            </h3>
            <div className="mt-1 flex items-center justify-center flex-wrap">
              {paywallContent?.featuredBlock.items?.map((el) => (
                <img className="h-7 w-auto my-1 mx-3" key={el} src={el} alt="Image" />
              ))}
            </div>
          </div>
        )}

        {paywallContent?.statisticsBlock && (
          <div className="flex flex-row flex-wrap border border-grayBlue">
            {paywallContent.statisticsBlock.items.map((item, index) => (
              <div
                key={index}
                className="w-1/3 flex flex-col gap-y-1 even:border-x border-grayBlue items-center bg-neutral-100 py-4"
              >
                <img src={item.imgSrc} alt={item.text} className="w-6 h-6" />
                <p className="text-progressBlock mt-[1px]">{item.title}</p>
                <p className="px-2 font-normal text-md2">{item.text}</p>
              </div>
            ))}
          </div>
        )}
        {paywallContent?.sliderBlock && (
          <ReviewsSlider
            title={paywallContent.sliderBlock.title}
            slides={paywallContent.sliderBlock.slides}
            note={paywallContent.sliderBlock.note}
            handleOpenReviewPopup={handleOpenReviewPopup}
            handleScrollToPlan={handleScrollToPlan}
          />
        )}

        {paywallContent?.moneyBackBlock && (
          <div className="mt-4 pr-4">
            <div className="flex text-left items-start relative pl-[3.7rem] ml-16 p-2 border border-secondary rounded-2xl bg-white">
              <p className="w-full text-left text-sm leading-[16.64px] font-normal text-dark-200">
                {paywallContent?.moneyBackBlock.content}
              </p>
              <div className="absolute top-1/2 -translate-y-1/2 left-0 -translate-x-1/2 w-24 h-24 bg-neutral-100 rounded-full">
                <img
                  className="object-contain"
                  src={paywallContent?.moneyBackBlock.img}
                  alt="100% money-back guarantee"
                />
              </div>
            </div>
            <p
              className="mx-4 text-left font-normal link-decoration text-xs leading-[14.08px] mt-1"
              dangerouslySetInnerHTML={{ __html: paywallContent?.moneyBackBlock.description ?? '' }}
            />
          </div>
        )}

        {paywallContent?.youWillGet && (
          <div className="flex pt-6 flex-col">
            <h3 className="text-center font-medium mb-4 text-questionName">
              {paywallContent.youWillGet.title}
            </h3>
            <div>
              <img src={paywallContent.youWillGet.imgSrc} alt={paywallContent.youWillGet.title} />
            </div>
            <div className="flex flex-col border-y border-grayBlue">
              {paywallContent?.youWillGet.items.map((item, index) => (
                <div key={index} className="flex flex-row">
                  <div className="px-4 pt-2 min-w-10 font-semibold text-questionName text-center bg-primary-blue-300">
                    {index + 1}
                  </div>
                  <div className="py-2 px-2 flex flex-col gap-y-1">
                    <h4 className="font-medium italic text-questionName">{item.title}</h4>
                    <p className="text-left font-normal text-md2 leading-[19.2px]">{item.text}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex w-full justify-center items-center py-4">
              <ScrollToPlanButton handleScrollToPlan={handleScrollToPlan} />
            </div>
          </div>
        )}
        {paywallContent?.userReviewsBlock && (
          <div className="flex py-4 flex-col">
            <div className="mb-2">
              <p
                className="uppercase text-secondary text-questionName font-semibold"
                dangerouslySetInnerHTML={{ __html: paywallContent.userReviewsBlock.title }}
              ></p>
            </div>
            <div className="flex flex-col gap-y-1 px-2">
              {paywallContent.userReviewsBlock.items.map((url, index) => (
                <div key={index} className="w-full">
                  <img src={url} alt={url} />
                </div>
              ))}
            </div>
          </div>
        )}

        {paywallContent?.plans?.items[activePlan] && (
          <p className="px-2 pt-2 text-xs leading-[14.08px] text-center font-normal text-dark-100 pb-6">
            {paywallContent?.plans?.items[activePlan]?.information}
          </p>
        )}
        <div
          className={`${shouldShowOverlayExpired && timerIsExpired && !isDiscount45 ? 'opacity-100 visibility' : 'opacity-0 delay-100  invisible'} `}
        >
          <TimerOverlay
            isShow={shouldShowOverlayExpired && timerIsExpired && !isDiscount45}
            onCloseOverlay={() => {
              setShouldShowOverlayExpired(false)
            }}
          />
        </div>
        <div
          className={`${shouldShowPercent45Overlay && isDiscount45 ? 'opacity-100 visibility' : 'opacity-0 delay-100  invisible'} `}
        >
          <Percent45Overlay
            isShow={shouldShowPercent45Overlay && isDiscount45}
            onCloseOverlay={() => {
              setShouldShowPercent45Overlay(false)
            }}
          />
        </div>
        {selectedReview && (
          // add class to lock body oveflow
          <div className="fixed top-0 left-1/2 -translate-x-1/2 h-full max-w-content w-full flex items-center justify-center backdrop-blur-sm transition-all duration-300 z-30">
            <ReviewPopup
              isOpen={!!selectedReview}
              onClose={handleCloseReviewPopup}
              review={selectedReview}
            />
          </div>
        )}
      </Layout>
    )
  )
}
