import React, { useEffect, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Answers, PaymentPlan, PaywallContent, Plan } from '@/types/types'

import StripeButton from '@/components/StripeCheckout/StripeButton'
import { formatPlan } from '@/helpers/formatPlan'
import {
  checkIfCustomerExist,
  createPaymentIntent,
  createStripeCustomer,
} from '@/helpers/stripeHelpers'
import { getValue } from '@/helpers/getObjectValue'

interface StripeCheckoutProps {
  plan: Plan
  isDiscount45: boolean
  paywallContent?: PaywallContent
  searchParams?: URLSearchParams
  onLoading: (val: boolean) => void
  onAvailableApplePay: (val: boolean) => void
  handleSelectPlan: () => void
}

const stripePublishKey = process.env.REACT_APP_STRIPE_PUBLISH_KEY ?? ''
const stripePromise = loadStripe(stripePublishKey)

export default function StripeApplePay(props: StripeCheckoutProps) {
  const [price, setPrice] = useState<number>(
    props.isDiscount45
      ? Math.round(props.plan.discountedPrice45 * 100)
      : Math.round(props.plan.discountedPrice30 * 100),
  )
  useEffect(() => {
    setPrice(
      props.isDiscount45
        ? Math.round(props.plan.discountedPrice45 * 100)
        : Math.round(props.plan.discountedPrice30 * 100),
    )
  }, [props.plan])

  const [paymentIntent, setPaymentIntent] = useState<any>()
  const storedData = localStorage.getItem('quizResult')
  const answers: Answers = storedData ? JSON.parse(storedData) : false
  const userEmail = getValue('email', answers) as string
  const userName = (getValue('your-name', answers) as string) ?? ('Without name' as string)
  const customerPortalID = localStorage.getItem('customerPortalId') as string
  const customer = { name: userName, email: userEmail, customerPortalID }
  const [plan, setPlan] = useState<PaymentPlan | undefined>(
    props.plan ? formatPlan(props.plan, props.isDiscount45) : undefined,
  )
  const [isLoading, setIsLoading] = useState(true)
  const [availableApplePay, setAvailableApplePay] = useState(true)
  const [isLoadingRequest, setIsLoadingRequest] = useState(false)

  useEffect(() => {
    setPlan(props.plan ? formatPlan(props.plan, props.isDiscount45) : undefined)
  }, [props.plan])

  useEffect(() => {
    props.onLoading(isLoading)
  }, [isLoading])

  useEffect(() => {
    props.onAvailableApplePay(availableApplePay)
  }, [availableApplePay])

  useEffect(() => {
    if (!isLoadingRequest && availableApplePay) {
      setIsLoading(true)
      setIsLoadingRequest(true)
      checkIfCustomerExist(customer).then((data) => {
        if (data.data.length) {
          const userID = data.data[0].id
          handleCreatePaymentIntent(userID)
        } else {
          createStripeCustomer(customer, customerPortalID, userEmail).then((data) => {
            handleCreatePaymentIntent(data.id)
          })
        }
      })
    }
  }, [plan, availableApplePay])

  const handleCreatePaymentIntent = (userID: string) => {
    if (customer) {
      createPaymentIntent(userID, price).then((data) => {
        setPaymentIntent(data)
        setIsLoading(false)
        setIsLoadingRequest(false)
      })
    }
  }

  if (!paymentIntent) {
    return null
  }

  const options = {
    clientSecret: paymentIntent.client_secret,
  }

  return (
    <>
      <Elements stripe={stripePromise} options={options}>
        <StripeButton
          paymentIntent={paymentIntent}
          handleSelectPlan={props.handleSelectPlan}
          onAvailableApplePay={(val) => setAvailableApplePay(val)}
          onLoading={(val) => setIsLoading(val)}
          activePlan={props.plan}
          isDiscount45={props.isDiscount45}
          price={price}
        />
      </Elements>
    </>
  )
}
