import React, { useEffect } from 'react'
import { Answer, Answers, Question, QuizVariantType } from '@/types/types'
import Content from '@/components/QuestionsUI/Content'
import RadioButton from '@/components/QuestionsUI/RadioButton'
import Input from '@/components/QuestionsUI/Input'
import { Button } from '@/components/Button/Button'
import CheckboxButton from '@/components/QuestionsUI/CheckboxButton'
import { useTranslation } from 'react-i18next'
import ProgressLottie from '../QuestionsUI/ProgressLottie'
import SliderComponent from '../QuestionsUI/SliderComponent'
import BottleGrid from '../QuestionsUI/BottleGrid'
import ImageRadioButton from '../QuestionsUI/ImageRadioButton'
import AfterQuizAnimation from '../QuestionsUI/AfterQuizAnimation'
import RateComponent from '../QuestionsUI/RateComponent'
import FormulaBlock from '../QuestionsUI/FormulaBlock'
import PreliminaryProcessing from '../QuestionsUI/PreliminaryProcessing'
import PrivilegiesList from '../QuestionsUI/PrivilegiesList'
import PeopleOnPlanet from '../QuestionsUI/PeopleOnPlanet'
import SvgCircle from '../QuestionsUI/SvgCircle'
import Camera from '../QuestionsUI/Camera'
import ReviewsSlider from '../QuestionsUI/ReviewsSlider'
import ProgressBars from '../QuestionsUI/ProgressBars'
import { useNavigate } from 'react-router-dom'
import { ProgressQuiz } from '@/components/QuestionsUI/ProgressQuiz'

interface QuestionStepProps {
  question: Question
  onAnswer: (answer: Answer, step?: number) => void
  onSubmit: () => void
  onShowNextButton: () => boolean
  onNextClick: () => void
  questionsLength: number
  handleRemoveAnswer: (answer: Answer) => void
  step: number
  active: boolean
  answers: Answers
  error: string
  progressQuestions: Array<Question[]>
  setError: React.Dispatch<React.SetStateAction<string>>
  quizVariant: QuizVariantType
  setBlockName: (value: React.SetStateAction<string>) => void
  setProgressString: (value: React.SetStateAction<string>) => void
  blockName: string
  progressString: string
  onSkipClick: (steps: number) => void
}

export default function QuestionStep(props: QuestionStepProps) {
  const { t } = useTranslation()
  useEffect(() => {
    if (props.question.noRequiredFields) {
      props.onAnswer({ [props.question.name]: [''] }, props.step)
    }
  }, [props.question.noRequiredFields])

  const navigate = useNavigate()

  useEffect(() => {
    if (props.active) {
      const allSteps =
        props?.progressQuestions &&
        []
          .concat(...(props.progressQuestions as []))
          .filter((el: Question) => el.excludeFromProgress !== true)

      const currentStep =
        allSteps.findIndex((q) => JSON.stringify(q) === JSON.stringify(props.question)) + 1

      const newString = `${t('Question: ')} ${currentStep} ${t(' of ')} ${allSteps.length}`

      if (props.question.hideProgress === true || props.question.excludeFromProgress === true) {
        props.setBlockName('')
        props.setProgressString('')
      } else {
        props.setProgressString(newString)
        props.question?.block && props.setBlockName(props.question.block)
      }
    }
    window.scrollTo(0, 0)
  }, [props.question, props.active])

  return (
    <>
      <div
        className={`
        ${props.active && (props.question.gotItButton || props.question.skipAnimation) ? 'pb-[42px] ' : ''}
        ${
          !props.active
            ? 'absolute top-0 opacity-0 pt-[26px] pointer-events-none max-h-full overflow-hidden  '
            : 'opacity-100 top-0  overflow-hidden relative'
        } 
        ${props.step !== 0 ? 'step-quiz' : ''}
        ${props.question.hideProgress === true || props.question.excludeFromProgress === true ? 'min-h-[calc(100%-62px)] ' : 'min-h-[calc(100%-26px)] '}
         w-full transition duration-500 container flex flex-col 
         ${props.quizVariant !== 'web2' ? 'pb-24' : 'pb-4'}
          ${props.question?.position === 'end' && props.question.nameLg && 'mb-12'}
           px-4 ${props.question?.position && props.question?.position === 'end' ? ' justify-end ' : props.question?.position === 'center' ? ' justify-center ' : ''}
      ${props.question.skipButton && 'pb-32'}`}
      >
        {props.quizVariant === 'default' && (
          <ProgressQuiz
            question={props.question}
            progressQuestions={props.progressQuestions}
            quizVariant={props.quizVariant}
            step={props.step}
          />
        )}

        {props.quizVariant === 'default' && props.blockName && (
          <h2 className="text-center text-progressBlock font-semibold pt-1 m-0 relative z-1">
            {t('Block: ') + props.blockName}
          </h2>
        )}
        <div
          className={`
        ${props.question.spaceBetween ? 'grow ' : ''}
          ${
            props.question.gotItButton ||
            props.question.position === 'center' ||
            props.question.disableBorder
              ? ' mt-0'
              : `${props.quizVariant === 'web2' ? 'bg-web2-question-bg question-border-web2' : 'backdrop-blur-md question-border'}  px-2 mt-8 max-mini:mt-6`
          } ${props.active && (!props.question.gotItButton || !props.question.skipAnimation) ? 'question-animation' : ' '} ${props.question?.addSpacing ? '!pt-4' : ''} relative
           ${props.question.gotItButton || props.question.skipAnimation ? '!pt-4' : ''}
        `}
          style={{
            padding: props.question?.styles?.padding ?? '0 8px 0 8px',
          }}
        >
          {props.question.name && (
            <h2
              className={`col-span-2 ${props.question.nameLg && 'text-web2secondary-100'} ${props.step === 0 || props.question.nameLg ? 'mini:text-questionStartName max-mini:text-xl2 max-mini:leading-7' : props.quizVariant === 'web2' ? 'text-questionName max-mini:text-lg max-mini:leading-6 mb-4 max-mini:mb-3' : 'text-questionName max-mini:text-lg max-mini:leading-6 mb-6 '} ${props.question.subtitle && 'text-questionStartName'}`}
            >
              <span dangerouslySetInnerHTML={{ __html: props.question.name }} />
              {props.question.nameMark && (
                <mark className="block bg-transparent text-mark">{props.question.nameMark}</mark>
              )}
            </h2>
          )}
          {props.question.description && (
            <p
              className="-mt-2 mb-6 max-mini:mb-4 text-base max-mini:text-md font-normal text-primary"
              dangerouslySetInnerHTML={{ __html: props.question.description }}
            ></p>
          )}
          {props.question.subtitle && (
            <p className="-mt-2 font-normal text-md2 max-mini:text-md1 italic">
              {props.question.subtitle}
            </p>
          )}
          <div
            className={
              props.question?.grid
                ? ' columns-2 gap-x-2'
                : props.question?.gridThree
                  ? ' columns-3 gap-x-2'
                  : ''
            }
          >
            {props?.question?.components.map((el, index) => (
              <div key={index} className="step">
                {el?.content && props.active && (
                  <Content quizVariant={props.quizVariant} content={el.content} />
                )}
                {el?.progress && (
                  <ProgressLottie
                    content={el.progress}
                    active={props.active}
                    nextStep={props.onNextClick}
                  />
                )}
                {el?.radioButton && (
                  <RadioButton
                    onAnswer={props.onAnswer}
                    quizVariant={props.quizVariant}
                    answers={props.answers}
                    name={`${props.question.fieldName ?? `radio-button-${props.step}`}`}
                    attributes={el.radioButton}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    triggerNextStep={!props.question.nextButton ? props.onNextClick : () => {}}
                  />
                )}
                {el.imageRadioButton && (
                  <ImageRadioButton
                    answer={props.answers[props.step]}
                    onAnswer={props.onAnswer}
                    attributes={el.imageRadioButton}
                    quizVariant={props.quizVariant}
                    name={`${props.question.fieldName ?? `image-radio-button-${props.step}`}`}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    triggerNextStep={!props.question.nextButton ? props.onNextClick : () => {}}
                  />
                )}
                {el?.checkboxButton && (
                  <CheckboxButton
                    step={props.step}
                    quizVariant={props.quizVariant}
                    onAnswer={props.onAnswer}
                    handleRemoveAnswer={props.handleRemoveAnswer}
                    name={`${props.question.fieldName ?? `checkbox-button-${props.step}`}`}
                    attributes={el.checkboxButton}
                  />
                )}
                {el?.input && (
                  <Input
                    name={el.input.name}
                    handleRemoveAnswer={props.handleRemoveAnswer}
                    onAnswer={props.onAnswer}
                    attributes={el.input}
                    step={props.step}
                    error={props.error}
                    active={props.active}
                    setError={props.setError}
                    triggerNextStep={props.onNextClick}
                  />
                )}
                {el?.image && props.active && (
                  <div className={el.image.className && 'min-w-image -left-image-gap relative'}>
                    <img
                      className={
                        (el.image.className && 'w-full') +
                        ' max-w-full min-w-60 mx-auto mt-6 mb-6 w-auto'
                      }
                      width={20}
                      height={20}
                      alt="Some content"
                      src={el.image.src}
                    />
                  </div>
                )}
                {el?.rate && (
                  <RateComponent
                    name={`rate-${props.step}`}
                    onAnswer={props.onAnswer}
                    count={el.rate.count}
                  />
                )}
                {el?.slider && (
                  <SliderComponent
                    name={`${props.question.fieldName ?? `slider-${props.step}`}`}
                    onAnswer={props.onAnswer}
                    step={props.step}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    triggerNextStep={!props.question.nextButton ? props.onNextClick : () => {}}
                  />
                )}
                {el?.formulaCircles && (
                  <FormulaBlock
                    attributes={el.formulaCircles}
                    onAnswer={props.onAnswer}
                    name={`formula-${props.step}`}
                    active={props.active}
                  />
                )}
                {/* Should I move this part to separate component ??? */}
                {el?.joinedCount && (
                  <div className="w-full flex flex-row items-center mt-10 max-mini:mt-8 mb-6 max-mini:mb-4 justify-start">
                    <picture>
                      <source srcSet={el.joinedCount.imgWebP} type="image/webp" />
                      <img
                        src={el.joinedCount.img}
                        alt={el.joinedCount.title}
                        className="w-20 h-8 object-contain"
                      />
                    </picture>
                    <p className="text-base max-mini:text-md ml-4 max-mini:ml-3 text-left font-normal">
                      {el.joinedCount.title}
                    </p>
                  </div>
                )}
                {/* ------------------------------------------------ */}
                {el?.privileges && <PrivilegiesList attributes={el.privileges} />}
                {el?.peopleDots && (
                  <PeopleOnPlanet
                    name={`${props.question.fieldName ?? `dots-${props.step}`}`}
                    onAnswer={props.onAnswer}
                    peopleDots={el.peopleDots}
                    active={props.active}
                  />
                )}
                {el?.imgRow && (
                  <>
                    {el.imgRow.map((item, index) => (
                      <div key={index} className="mt-4 max-mini:mt-3">
                        <picture>
                          <source srcSet={item.imgWebP} type="image/webp" />
                          <img
                            className="max-w-[136px] object-contain"
                            src={item.img}
                            alt={item.title}
                          />
                        </picture>
                        <p
                          className="mt-3 max-mini:mt-2 max-mini:text-md text-left min-w-[50%] font-normal"
                          dangerouslySetInnerHTML={{ __html: item?.content ?? '' }}
                        />
                      </div>
                    ))}
                  </>
                )}
                {el?.formulaCol && (
                  <div className="flex flex-col items-start w-full gap-y-2 max-mini:gap-y-1.5 pt-4 max-mini:pt-3 pb-6 max-mini:pb-5">
                    {el.formulaCol.map((item, index) => (
                      <div
                        key={index}
                        className="flex flex-row items-center gap-x-4 max-mini:gap-x-3"
                      >
                        <SvgCircle width={56} item={item} />
                        <p
                          className="text-base max-mini:text-md font-normal text-left"
                          dangerouslySetInnerHTML={{ __html: item.title }}
                        ></p>
                      </div>
                    ))}
                  </div>
                )}
                {el?.camera && (
                  <Camera
                    triggerNextStep={props.onNextClick}
                    attributes={el.camera}
                    current={props.active}
                  />
                )}
                {el?.reviewsSlider && (
                  <ReviewsSlider current={props.active} slidesContent={el.reviewsSlider} />
                )}
                {el?.progressBars && (
                  <ProgressBars
                    active={props.active}
                    bars={el.progressBars.fields}
                    question={el.progressBars.question}
                    onAnswer={props.onAnswer}
                    triggerNextStep={
                      props.questionsLength === props.step + 1 ? props.onSubmit : props.onNextClick
                    }
                    name={`${props.question.fieldName ?? `question-${props.step}`}`}
                  />
                )}
                {el?.bottleGrid && (
                  <BottleGrid
                    name={`${props.question.fieldName ?? `bottles-${props.step}`}`}
                    onAnswer={props.onAnswer}
                  />
                )}
                {el?.preliminaryProcessing && (
                  <PreliminaryProcessing
                    attributes={el.preliminaryProcessing}
                    curent={props.active}
                    triggerNextStep={props.onNextClick}
                    name={`${props.question.fieldName ?? `tried-${props.step}`}`}
                    onAnswer={props.onAnswer}
                  />
                )}
                {el?.afterQuizAnimation && (
                  <AfterQuizAnimation
                    attributes={el.afterQuizAnimation}
                    curent={props.active}
                    triggerNextStep={props.onNextClick}
                    quizVariant={props.quizVariant}
                  />
                )}
              </div>
            ))}
          </div>
          {props.quizVariant === 'web2' &&
            props.step + 1 !== props.questionsLength &&
            props.question.nextButton && (
              <div className="min-w-full flex flex-row items-center justify-between mt-6 mb-2 max-mini:mt-4 max-mini:mb-[0.3rem] [&>button]:w-[49%]">
                <Button
                  className="bg-web2-primary-100 leading-[1.2] rounded-button py-2 px-4 border border-web2-neutral-300 !text-web2-neutral-300 font-medium text-questionName max-mini:text-lg max-mini:leading-6 disabled:bg-web2-primary-100 !disabled:text-web2-neutral-100 disabled:border-web2-neutral-100 transition-all duration-300"
                  onClick={() => navigate(-1)}
                  disabled={props.step < 2}
                >
                  {t('Back')}
                </Button>
                <Button
                  onClick={props.onNextClick}
                  disabled={props.onShowNextButton()}
                  className="py-2 px-4 rounded-button leading-[1.2] border-primary text-primary font-medium text-questionName max-mini:text-lg max-mini:leading-6 bg-web2-primary-300 disabled:bg-web2-neutral-100 disabled:text-web2-neutral-300 disabled:border-web2-neutral-100 transition-all duration-300"
                >
                  {t('Next')}
                </Button>
              </div>
            )}
        </div>

        {props.quizVariant !== 'web2' && (
          <div className="button-navigation button fixed bottom-10 max-w-content w-full container left-1/2 -translate-x-1/2 ">
            {props.step + 1 !== props.questionsLength && props.question.nextButton && (
              <Button
                onClick={props.onNextClick}
                disabled={props.onShowNextButton()}
                className="btn-large"
              >
                {t('Next')}
              </Button>
            )}
            {props.question.gotItButton && (
              <div className="w-full flex flex-col gap-y-2">
                <Button
                  onClick={props.onNextClick}
                  className={`btn-large ${props.question.skipButton && 'bg-web2-primary-500 '}`}
                >
                  {props.question.gotItButtonText ?? t('Got it')}
                </Button>
                {props.question.skipButton && (
                  <Button
                    onClick={() => props.onSkipClick(props?.question?.skipButton?.stepsToSkip ?? 1)}
                    className="btn-large"
                  >
                    {props.question.skipButton.title ?? t('Skip next question')}
                  </Button>
                )}
              </div>
            )}
            {props.step + 1 === props.questionsLength && (
              <Button
                disabled={props.onShowNextButton()}
                onClick={() => props.onSubmit()}
                className="btn-large"
                variant={'last'}
              >
                {t('Get My Plan')}
              </Button>
            )}
          </div>
        )}
      </div>

      {props.quizVariant === 'web2' && props.question.gotItButton && props.active && (
        <div className={'sticky bottom-[20px] w-full left-0 px-4 flex flex-col mt-3 gap-y-2'}>
          <Button
            onClick={props.onNextClick}
            className={`${props.question.skipButton && '!bg-web2-primary-500 !border-primary'} !leading-[1.2] !text-questionName max-mini:!text-lg max-mini:!leading-6 text-primary bg-neutral-100 border-web2-neutral-100`}
          >
            {props.question.gotItButtonText ?? t('Got it')}
          </Button>
          {props.question.skipButton && (
            <Button
              onClick={() => props.onSkipClick(props?.question?.skipButton?.stepsToSkip ?? 1)}
              className="!text-questionName text-primary !leading-[1.2] max-mini:!text-lg max-mini:!leading-6 bg-neutral-100 border-web2-neutral-100"
            >
              {props.question.skipButton.title ?? t('Skip next question')}
            </Button>
          )}
        </div>
      )}
    </>
  )
}
