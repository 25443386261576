import React, { useEffect, useRef, useState } from 'react'
import Webcam from 'react-webcam'
import { Button } from '../Button/Button'
import { FancyFaceLogo } from '../ui/Icons'
import { useTranslation } from 'react-i18next'
import { CameraComponentInterface, CameraComponentStep } from '@/types/types'

interface CameraComponentProps {
  current: boolean
  attributes: CameraComponentInterface
  triggerNextStep: () => void
}

const Camera = (props: CameraComponentProps) => {
  const { t } = useTranslation()
  const cameraRef = useRef<Webcam>(null)
  const screenRef = useRef<HTMLDivElement>(null)
  const [cameraStep, setCameraStep] = useState<CameraComponentStep>(props.attributes.before)
  const [image, setImage] = useState<string | null | undefined>(null)
  const skanningSeconds = 10
  const [currentText, setCurrentText] = useState(0)

  const handleTakePicture = () => {
    if (cameraRef !== null) {
      setImage(cameraRef.current?.getScreenshot())
      setTimeout(() => {
        setCameraStep(props.attributes.processing)
      }, 300)
    }
  }

  const handleRetakePicture = () => {
    setImage(null)
    setCameraStep(props.attributes.before)
  }

  const handleScanFace = () => {
    setCameraStep(props.attributes.after)
    setAnalazingText()
    setTimeout(() => {
      props.triggerNextStep()
      setCameraStep(props.attributes.before)
      setCurrentText(0)
      setImage(null)
    }, skanningSeconds * 1000)
  }

  const setAnalazingText = () => {
    setTimeout(() => {
      setCurrentText((prev) => prev + 1)
    }, 1800)
  }

  useEffect(() => {
    if (
      currentText > 0 &&
      props.attributes?.after?.texts &&
      currentText <= props.attributes.after.texts.length - 1
    ) {
      setAnalazingText()
    }
  }, [currentText])

  return (
    <>
      {props.current && (
        <div
          className={`${props.attributes.hidden ? 'hidden' : ''} overflow-y-auto overflow-x-clip fixed top-0 left-0 h-[100dvh] z-10 w-full bg-background transition-all duration-300 flex justify-center`}
        >
          <div
            ref={screenRef}
            className="w-full min-h-[740px] j max-w-content relative z-20 flex camera-parent flex-col gap-y-4 max-mini:gap-y-2"
          >
            {image ? (
              <>
                <img
                  src={image}
                  alt="Face image"
                  className="absolute top-0 left-0 w-full h-full object-cover "
                />
              </>
            ) : (
              <Webcam
                ref={cameraRef}
                screenshotQuality={1}
                screenshotFormat="image/jpeg"
                imageSmoothing={false}
                mirrored={true}
                videoConstraints={{
                  width: 1600,
                  height: 1000,
                }}
                className="absolute w-full top-0 h-full left-0 object-cover"
              />
            )}
            <div className="absolute top-0 left-0 w-full h-full">
              <img
                src="/img/quiz-2/bg.svg"
                alt="Background"
                className="w-full object-cover h-full border-image"
              />
            </div>
            <div className="w-full flex flex-col gap-y-1 max-mini:gap-y-0.5 px-4 relative min-h-[100%] justify-between !z-[100px]">
              <div className="flex flex-row w-full items-center justify-between relative z-50 mt-[16.5px] z-1">
                <div className="flex flex-row items-end gap-x-[6px]">
                  <FancyFaceLogo color="#CD037B" />
                  <div className="flex flex-col items-start">
                    <h1 className="text-questionName font-semibold">{t('Fancy Face')}</h1>
                    <p className="text-sm leading-[16.64px] -mt-[3px] italic">{t('face yoga')}</p>
                  </div>
                </div>
              </div>
              <div className="px-2 grow pt-4 pb-[68px] gap-[20px] max-mini:gap-2.5 relative z-50 h-full flex flex-col items-center justify-between">
                <p className="text-web2secondary-100 text text-questionName max-mini:text-lg max-mini:leading-6 font-semibold">
                  {cameraStep.label}
                </p>
                <div className="relative camera-elipsis-wrapper">
                  <div
                    className={`camera-elipsis w-full h-full absolute top-0 left-0 ${props.attributes.after.label === cameraStep.label ? 'before:visible before:running' : 'before:invisible before:paused'}`}
                  ></div>
                </div>
                <div className="w-full h-24 flex relative z-50 flex-col justify-start gap-y-2 max-mini:gap-y-1">
                  {cameraStep.buttons &&
                    cameraStep.buttons.map((item, index) => (
                      <Button
                        key={index}
                        className="text-questionName max-mini:text-lg max-mini:leading-5"
                        style={{ backgroundColor: item.bg }}
                        onClick={
                          item.makeScreenshot
                            ? handleTakePicture
                            : item.retakePhoto
                              ? handleRetakePicture
                              : handleScanFace
                        }
                      >
                        {item.text}
                      </Button>
                    ))}
                  {props.attributes.after.texts &&
                    props.attributes.after.label === cameraStep.label && (
                      <div className="text-center text-questionName max-mini:text-lg max-mini:leading-5 text-web2secondary-100 mt-5">
                        {props.attributes.after.texts[currentText]}
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Camera
