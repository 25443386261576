import React, { useEffect, useState } from 'react'
import { ContentType, QuizVariantType } from '@/types/types'
import Review from './Review'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper/modules'
import { useLottie } from 'lottie-react'
import 'swiper/css'

interface ContentProps {
  content: ContentType
  quizVariant: QuizVariantType
}

export default function Content(props: ContentProps) {
  const { t } = useTranslation()
  const [animationData, setAnimationData] = useState<any>(undefined)
  const [days, setDate] = useState(new Date())
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isAnimationLoaded, setIsAnimationLoaded] = useState(false)

  useEffect(() => {
    if (props?.content?.lottie?.src) {
      fetch(props.content.lottie.src)
        .then((response) => response.json())
        .then((data) => setAnimationData(data))
        .catch((error) => console.error('Error fetching animation data:', error))
    }
  }, [props.content.lottie?.src])

  useEffect(() => {
    const newDate = new Date()
    newDate.setDate(newDate.getDate() + 28)
    setDate(newDate)
  }, [])

  const options = {
    animationData,
    loop: false,
    autoplay: false,
  }

  const { View, play } = useLottie(options)

  useEffect(() => {
    if (animationData) {
      play()
    }
  }, [animationData, play])
  return (
    <>
      {props?.content?.date && (
        <div className="flex justify-center mb-4">
          <div className="text-white bg-gradient text-center text-lg font-bold py-2 px-6 rounded-lg">
            {props.content.date.text +
              days.toLocaleString('en-us', { month: 'short', day: 'numeric' })}
          </div>
        </div>
      )}
      {props?.content?.paragraph && (
        <p
          className={`${props?.content?.paragraph?.bold && ' font-bold'} text-paragraph gotItTitle mt-4 max-mini:mt-3 max-mini:text-lg max-mini:leading-6`}
          dangerouslySetInnerHTML={{ __html: props.content.paragraph.text }}
        ></p>
      )}
      {props?.content?.smallParagraph && (
        <p className="max-w-72 m-auto mt-2">
          <small className="text-md text-gray">{props.content.smallParagraph.text}</small>
        </p>
      )}
      {props?.content?.smallSpan && (
        <p className="text-start text-xs mt-2">
          <small className="text-xs font-normal">{props.content.smallSpan.text}</small>
        </p>
      )}
      {/* Quote for quiz 3, 9, 18, 31 */}
      {props?.content?.quote && (
        <div className="relative before:absolute before:w-[3px] before:h-full before:bg-secondary before:rounded-full mt-2 max-mini:mt-[0.4rem] mb-6 max-mini:mb-[1.35rem]">
          <p
            className={`ml-2.5 text-left font-normal ${props.quizVariant !== 'web2' ? 'italic ' : ''} text-underName max-mini:text-md max-mini:leading-[1.35rem]`}
          >
            {props.content.quote.text}
          </p>
        </div>
      )}
      {/* --------------------------- */}
      {/* Ordered list for quiz 3, 31 */}
      {props?.content?.orderList && (
        <ol
          className={`${props?.content?.orderList.italic && 'italic'} list-decimal mt-2 ml-2.5 text-ol max-mini:text-sm max-mini:leading-5 max-mini:mt-[0.4rem]`}
        >
          {props.content.orderList.items.map((item: string, index: number) => (
            <li key={index} dangerouslySetInnerHTML={{ __html: item }}></li>
          ))}
        </ol>
      )}
      {/* --------------------------- */}
      {props?.content?.plainText && (
        <p
          // className={props?.content?.plainText.italic && 'italic' + ' text-justify font-normal'}
          className={`${props.content.plainText?.styles ? props.content.plainText.styles : ' '} ${props.content.plainText?.italic && ' italic '} ${props.content.plainText?.underName ? ' -mt-3 mb-6 font-normal text-underName italic ' : ' text-underName font-normal text-left mt-2'}`}
          dangerouslySetInnerHTML={{ __html: props.content.plainText.text }}
        ></p>
      )}
      {/* ---------- */}
      {/* Unordered list for quiz 31 */}
      {props?.content?.unorderList && (
        <ul
          className={`${props?.content?.unorderList.italic && 'italic'} mt-2 list-disc ml-2.5 max-mini:text-sm max-mini:leading-5 max-mini:mt-[0.4rem]`}
        >
          {props.content.unorderList.items.map((item: string, index: number) => (
            <li key={index} dangerouslySetInnerHTML={{ __html: item }}></li>
          ))}
        </ul>
      )}
      {/* -------------------------- */}
      {props?.content?.smallText && (
        <p
          className={`text-xs2 italic font-normal text-left ${props.quizVariant === 'web2' ? 'mt-2 text-gray' : 'mt-4 mx-6'}`}
        >
          {props.content.smallText.text}
        </p>
      )}
      {props?.content?.image && (
        <div className={props?.content?.image.className && 'min-w-image -left-image-gap relative'}>
          <img
            className={
              (props?.content?.image.className && 'w-full') +
              ' max-w-full min-w-60 mx-auto mt-6 mb-6 w-auto'
            }
            width={20}
            height={20}
            alt="Some content"
            src={props?.content?.image.src}
          />
        </div>
      )}
      {props?.content?.quizIntro && (
        <div className="flex flex-col mb-14 max-mini:mb-12 max-mini:gap-y-3 gap-y-4 items-center">
          <p className="text-web2secondary-100 text-progressBlock max-mini:text-md max-mini:leading-[1.15rem] text-center font-medium">
            {props.content.quizIntro.subtext}
          </p>
          <h2 className="text-web2secondary-100 text-questionName max-mini:text-lg max-mini:leading-6 text-center p-0 m-0 font-semibold">
            {props.content.quizIntro.text}
          </h2>
        </div>
      )}
      {props?.content?.lottie && (
        <div
          className={props?.content?.lottie.className ? 'min-w-image -left-image-gap relative' : ''}
        >
          {View}
        </div>
      )}
      {props?.content?.imageAbsolute && (
        <div className="min-w-image -left-image-gap absolute top-1/4 -translate-y-1/3">
          <img
            className="max-w-full  mx-auto mt-6 w-full"
            width={20}
            height={20}
            alt="Some content"
            src={props?.content?.imageAbsolute.src}
          />
        </div>
      )}
      {props?.content?.reviews && (
        <>
          {props?.content?.slider ? (
            <Swiper
              modules={[Autoplay]}
              className="!overflow-visible mt-10"
              spaceBetween={8}
              loop={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
            >
              {props?.content?.reviews.map((review, index) => {
                return (
                  <SwiperSlide key={index}>
                    <Review
                      className="mt-2"
                      title={review.title}
                      quote={review.quote}
                      stars={review.stars}
                      date={review?.date}
                      name={review?.name}
                    />
                  </SwiperSlide>
                )
              })}
            </Swiper>
          ) : (
            <div>
              <h2 className="text-start mb-2 mt-6">{t('Recent reviews')}</h2>
              {props?.content?.reviews.map((review, index) => {
                return (
                  <Review
                    className="mt-2"
                    key={index}
                    title={review.title}
                    quote={review.quote}
                    stars={review.stars}
                    date={review?.date}
                    name={review?.name}
                  />
                )
              })}
            </div>
          )}
        </>
      )}
    </>
  )
}
