import React, { ChangeEvent, useRef } from 'react'
import { Answer, CheckboxButtonType, QuizVariantType } from '@/types/types'

interface CheckboxButtonProps {
  attributes: CheckboxButtonType
  onAnswer: (answer: Answer, step?: number) => void
  handleRemoveAnswer: (answer: Answer) => void
  name: string
  step: number
  quizVariant: QuizVariantType
}

export default function CheckboxButton(props: CheckboxButtonProps) {
  const checkbox = useRef<HTMLInputElement>(null)
  const reset: NodeListOf<HTMLInputElement> = document.querySelectorAll('input[data-reset]')

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked && !e.target.dataset.reset) {
      props.onAnswer({ [props.name]: [props.attributes.text] })
      reset.forEach((element: HTMLInputElement) => {
        if (element.getAttribute('name') === e.target.getAttribute('name') && element.checked) {
          element.checked = false
          props.onAnswer({ [props.name]: ['remove-' + element.value] })
        }
      })
    } else if (!e.target.checked && !e.target.dataset.reset) {
      props.onAnswer({ [props.name]: ['remove-' + props.attributes.text] })
    }
    if (e.target.dataset.reset) {
      const targetName = e.target.getAttribute('name')
      const inputs: NodeListOf<HTMLInputElement> =
        document.querySelectorAll('input[type="checkbox"]')
      inputs.forEach((element: HTMLInputElement) => {
        if (e.target !== element) {
          props.onAnswer({ [props.name]: ['remove-' + element.value] })
        }
        element.checked = element.getAttribute('name') === targetName && e.target === element
      })
      props.onAnswer({ [props.name]: [props.attributes.text] })
    }
  }

  return (
    <div>
      <label
        className={
          'flex bg-input items-center checkbox font-inputWeight border relative border-border rounded-button mb-2 max-mini:mb-[0.3rem] mt-0 px-4 py-2 cursor-pointer text-questionName max-mini:text-lg max-mini:leading-6 font-normal transition duration-300  ' +
          (checkbox.current?.checked
            ? props.quizVariant === 'web2'
              ? '!bg-web2-primary-300 border-primary'
              : ' after:bg-white bg-white border-border after:bg-tick after:bg-center after:bg-60% after:bg-no-repeat '
            : props.quizVariant === 'web2'
              ? 'bg-neutral-100 border-web2-neutral-100'
              : '')
        }
      >
        {props.attributes?.textIcon && (
          <span className="block mr-4 max-mini:mr-[0.3rem] text-xl2 max-mini:text-xl max-mini:leading-[20px]">
            {props.attributes?.textIcon}
          </span>
        )}
        {props.attributes.text}
        <input
          type="checkbox"
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
          name={props.name}
          value={props.attributes.text}
          className="hidden"
          ref={checkbox}
          data-reset={props.attributes.resetCheckbox}
        />
      </label>
    </div>
  )
}
