import { ImageTitleInterface } from '@/types/types'
import React from 'react'

interface PrivilegiesListProps {
  attributes: ImageTitleInterface[]
}

const PrivilegiesList = (props: PrivilegiesListProps) => {
  return (
    <ul className="flex flex-col w-full gap-y-2 max-mini:gap-y-1.5">
      {props.attributes.map((item, index) => (
        <li key={index} className="flex text-left flex-row items-center gap-x-1">
          <img
            src={item.img}
            alt={item.title}
            className="w-6 max-mini:w-5 h-6 max-mini:h-5 object-contain"
          />
          <p className="text-base max-mini:text-md text-left font-normal">{item.title}</p>
        </li>
      ))}
    </ul>
  )
}

export default PrivilegiesList
