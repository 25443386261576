import React, { useEffect, useState } from 'react'

interface ReviewPopup {
  isOpen: boolean
  onClose: () => void
  review: {
    user: string
    text: string
  }
}

const ReviewPopup = (props: ReviewPopup) => {
  if (!props.isOpen) return null
  const [popupOpened, setPopupOpened] = useState(false)
  useEffect(() => {
    if (props.isOpen) {
      setTimeout(() => setPopupOpened(true), 100)
    } else {
      setPopupOpened(false)
    }
  }, [props.isOpen])

  const handleClosePopup = () => {
    setPopupOpened(false)
    setTimeout(() => props.onClose(), 100)
  }
  return (
    <div
      className={`${!popupOpened ? 'opacity-0' : 'opacity-100'} bg-white p-5 border rounded transition-all duration-50 shadow-sm flex flex-col gap-y-2 w-10/12`}
    >
      <div className="flex flex-row justify-between items-center">
        <div className="italic font-bold text-md2">{props.review.user}</div>
        <div
          onClick={handleClosePopup}
          className="w-5 h-5 cursor-pointer text-lg flex justify-center items-center"
        >
          <img src={'/img/close-window.svg'} alt="" />
        </div>
      </div>
      <p
        className="text-left font-normal text-md2"
        dangerouslySetInnerHTML={{ __html: props.review.text }}
      ></p>
    </div>
  )
}

export default ReviewPopup
