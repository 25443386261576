import { logPixelRequest } from '@/helpers/logPixelRequest'

export const purchaseFacebookApi = async (dataObj: object, email: string) => {
  try {
    const response = await fetch(
      `https://graph.facebook.com/v20.0/${process.env.REACT_APP_FB_PIXEL_ID ?? ''}/events?access_token=${process.env.REACT_APP_FB_PIXEL_TOKEN_ID ?? ''}`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataObj),
      },
    )
    const data = await response.json()
    await logPixelRequest(dataObj, data, email)
    return data
  } catch (e) {
    return { status: 412, body: { error: e } }
  }
}
