import React, { useEffect, useState } from 'react'
import { ExpressCheckoutElement, useElements, useStripe } from '@stripe/react-stripe-js'
import {
  checkPaymentIntent,
  createScheduleSubscriptionForCustomer,
  createSubscriptionForCustomer,
  getCustomerPaymentMethod,
} from '@/helpers/stripeHelpers'
import { PaymentPlan, Plan } from '@/types/types'
import { formatPlan } from '@/helpers/formatPlan'
import { setSuccessPayment } from '@/helpers/setSuccessPayment'
import { useNavigate } from 'react-router-dom'
import ErrorMessage from '@/components/StripeCheckout/ErrorMessage'
import {
  trackClickApplePay,
  trackDefaultPaymentLoaded,
  trackLoadedApplePay,
} from '@/helpers/facebookPixelEvents'

interface StripeButtonProps {
  activePlan: Plan
  isDiscount45: boolean
  paymentIntent: any
  price: number
  onLoading: (val: boolean) => void
  onAvailableApplePay: (val: boolean) => void
  handleSelectPlan: () => void
}

export default function StripeButton(props: StripeButtonProps) {
  const { activePlan, paymentIntent } = props
  const navigate = useNavigate()
  const [plan] = useState<PaymentPlan | undefined>(
    activePlan ? formatPlan(props.activePlan, props.isDiscount45) : undefined,
  )
  const elements = useElements()
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const stripe = useStripe()
  const [isLoading, setIsLoading] = useState(true)
  const [availableApplePay, setAvailableApplePay] = useState(true)

  useEffect(() => {
    props.onLoading(isLoading)
  }, [isLoading])

  useEffect(() => {
    props.onAvailableApplePay(availableApplePay)
  }, [availableApplePay])

  const onConfirm = async () => {
    if (paymentIntent && stripe && elements) {
      const url = window.location.origin + '/paywall'
      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret: paymentIntent?.client_secret as string,
        confirmParams: {
          return_url: url,
        },
        redirect: 'if_required',
      })

      if (error) {
        setErrorMessage(error.code)
      } else {
        setErrorMessage('')
        setIsLoading(true)
        checkPaymentIntent(paymentIntent.id).then((res) => {
          if (res.status === 'succeeded' && plan) {
            const customerID = res.customer
            getCustomerPaymentMethod(customerID).then((res) => {
              const paymentMethodId = res.data[0].id
              createSubscriptionForCustomer(customerID, plan, true, paymentMethodId).then((res) => {
                if (res.status === 'active') {
                  if (activePlan.nextProductStripeID) {
                    const subscriptionObj = {
                      id: res.id,
                      current_period_end: res.current_period_end,
                      current_period_start: res.current_period_start,
                      plan: res.plan.id,
                    }
                    createScheduleSubscriptionForCustomer(subscriptionObj, plan).then(() => {
                      setIsLoading(false)
                      setSuccessPayment(res, navigate)
                    })
                  } else {
                    setIsLoading(false)
                    setSuccessPayment(res, navigate)
                  }
                }
              })
            })
          }
        })
      }
    }
  }

  return (
    <div className="w-full pt-2 relative">
      <ExpressCheckoutElement
        options={{
          buttonType: {
            applePay: 'buy',
            googlePay: 'buy',
          },
          paymentMethodOrder: ['apple_pay', 'google_pay', 'link'],
        }}
        onReady={(event) => {
          const isAvailableApplePay = !!event.availablePaymentMethods?.applePay
          setIsLoading(false)
          setAvailableApplePay(isAvailableApplePay)
          if (isAvailableApplePay) {
            trackLoadedApplePay()
          } else {
            trackDefaultPaymentLoaded()
          }
        }}
        onClick={(e) => {
          e.resolve()
          trackClickApplePay()
        }}
        onConfirm={onConfirm}
      />
      {errorMessage && (
        <ErrorMessage
          onClose={(selectPlan) => {
            if (selectPlan) {
              props.handleSelectPlan()
            }
            setErrorMessage('')
          }}
          isOpen={!!errorMessage}
        />
      )}
    </div>
  )
}
