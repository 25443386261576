import React, { useEffect, useState } from 'react'
import { Answer, Answers, QuizVariantType, RadioButtonType } from '@/types/types'
import { getValue } from '@/helpers/getObjectValue'

interface RadioButtonProps {
  attributes: RadioButtonType
  onAnswer: (answer: Answer) => void
  triggerNextStep: () => void
  name: string
  answers: Answers
  quizVariant: QuizVariantType
}

export default function RadioButton(props: RadioButtonProps) {
  const [checked, setChecked] = useState<boolean>(false)

  useEffect(() => {
    const fieldValue = getValue(props.name, props.answers)
    if (
      fieldValue ===
      props.attributes.text + (props.attributes.img ? 'img:' + props.attributes.img : '')
    ) {
      setChecked(true)
    } else {
      setChecked(false)
    }
  }, [props.answers])

  const handleChange = () => {
    props.onAnswer({
      [props.name]: [
        props.attributes.text + (props.attributes.img ? 'img:' + props.attributes.img : ''),
      ],
    })
    setTimeout(() => {
      props.triggerNextStep()
    }, 300)
  }

  const handleClick = () => {
    if (checked) {
      setTimeout(() => {
        props.triggerNextStep()
      }, 300)
    }
  }
  return (
    <div>
      <label
        className={`${checked ? (props.quizVariant === 'web2' ? '!bg-web2-primary-300 !border-primary' : '!bg-checked border-borderChecked ') : ''} ${props.attributes.textCenter && ' text-center justify-center items-center '} font-button border transition duration-300 ${props.quizVariant === 'web2' ? 'border-web2-neutral-100' : 'border-border'}  mb-2 max-mini:mb-[0.3rem] mt-0 px-4 py-2 cursor-pointer flex items-center bg-neutral-100 rounded-button ${props.name === 'radio-button-0' ? ' justify-center ' : 'justify-start'}`}
      >
        {props.attributes?.textIcon && (
          <span className="block mr-2 max-mini:mr-[0.3rem] text-xl2 max-mini:text-xl max-mini:leading-[20px]">
            {props.attributes?.textIcon}
          </span>
        )}
        <div className="flex flex-col font-medium text-questionName max-mini:text-lg max-mini:leading-6 gap-y-1 max-mini:gap-y-0.5 justify-start">
          {props.attributes.text}
          {props?.attributes?.subtext && (
            <p className="font-normal text-left text-border text-md1 max-mini:text-sm max-mini:leading-4">
              {props.attributes.subtext}
            </p>
          )}
        </div>
        <input
          type="radio"
          onChange={handleChange}
          name={props.name}
          value={props.attributes.text}
          className="hidden"
          checked={checked}
          onClick={handleClick}
        />
      </label>
    </div>
  )
}
