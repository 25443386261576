import React, { useState } from 'react'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { PaymentPlan, PaywallContent, Subscription } from '@/types/types'
import Subtotal from '@/components/CheckoutUI/Subtotal'
import { Button } from '@/components/Button/Button'
import { setSuccessPayment } from '@/helpers/setSuccessPayment'
import { NavigateFunction } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { createScheduleSubscriptionForCustomer } from '@/helpers/stripeHelpers'
import { clickPurchaseButton, selectPaymentMethod } from '@/helpers/facebookPixelEvents'
import PayPallIcon from '@/assets/images/PayPal.svg'
import ApplePay from '@/assets/images/Apple_Pay_logo white.svg'
import VerifiedIcon from '@/assets/images/verified.svg'

interface CheckoutFormProps {
  plan: PaymentPlan
  paywallContent: PaywallContent
  navigate: NavigateFunction
  subscription: Subscription
  handleError: (error: string) => void
  handleIsLoading: (loading: boolean) => void
}

export default function CheckoutForm(props: CheckoutFormProps) {
  const { t } = useTranslation()
  const stripe = useStripe()
  const elements = useElements()
  const defaultButton = { title: t('CONTINUE'), class: 'btn' }
  const [counter, setCounter] = useState(0)
  const [submitButton, setSubmitButton] = useState(defaultButton)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleClickPurchaseButton(submitButton.class === 'btn' ? 'card' : submitButton.class)

    if (!stripe || !elements) {
      return
    }

    props.handleIsLoading(true)

    const url = window.location.origin + '/checkout'

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: url,
      },
      redirect: 'if_required',
    })

    if (result.error) {
      if (result.error.message) {
        props.handleError(result.error.message)
      }
    } else {
      if (props.plan.nextProductStripeID) {
        createScheduleSubscriptionForCustomer(props.subscription, props.plan).then(() => {
          setSuccessPayment(result, props.navigate)
        })
      } else {
        setSuccessPayment(result, props.navigate)
      }
    }

    props.handleIsLoading(false)
  }

  const handleChangePaymentMethod = (e: { value: { type: string } }) => {
    switch (e?.value?.type) {
      case 'paypal':
        setSubmitButton({ title: 'PayPal', class: 'paypall' })
        break
      case 'applepay':
        setSubmitButton({ title: 'Buy with', class: 'apple-pay' })
        break
      default:
        setSubmitButton(defaultButton)
    }
    setCounter((prev) => ++prev)
    if (counter > 1) {
      selectPaymentMethod(e.value.type)
    }
  }

  const handleClickPurchaseButton = (method: string) => {
    clickPurchaseButton(method)
  }

  return (
    <>
      <form onSubmit={(e) => handleSubmit(e)} className="w-full flex flex-col">
        <Subtotal paywallContent={props.paywallContent} plan={props.plan} />
        <PaymentElement
          onChange={(e) => {
            handleChangePaymentMethod(e)
          }}
          onReady={() => props.handleIsLoading(false)}
        />
        <div className="mt-4">
          <Button
            className={`${submitButton.title} ${submitButton.class === 'apple-pay' ? 'bg-black [&>span]:text-white font-semibold' : 'bg-checkoutButton font-bold'} rounded-xl flex justify-center items-center border-none text-xl2 leading-7 gap-x-2 py-4`}
            style={{
              fontFamily: 'Arial',
            }}
          >
            {submitButton.class === 'paypall' && (
              <img src={PayPallIcon} alt="PaypallIcon" width={90} height={24} />
            )}
            {submitButton.class === 'apple-pay' && (
              <>
                <span>{submitButton.title}</span>
                <img src={ApplePay} alt="ApplePay" width={55} height={24} className="pt-1" />
              </>
            )}
            {submitButton.class === 'submit-dft' && (
              <img src={VerifiedIcon} alt="Verified" width={24} height={24} />
            )}
            {submitButton.class === 'btn' && <span>{submitButton.title}</span>}
          </Button>
        </div>
      </form>
    </>
  )
}
