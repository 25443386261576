import { Answer, FormulaCircle } from '@/types/types'
import React from 'react'
import { PlusIcon } from '../ui/Icons'
import SvgCircle from './SvgCircle'

interface FormulaBlockProps {
  name: string
  onAnswer: (answer: Answer, step?: number) => void
  attributes: FormulaCircle[]
  active: boolean
}

const FormulaBlock = (props: FormulaBlockProps) => {
  return props.active ? (
    <div className="w-full flex flex-row items-center justify-between">
      {props.attributes.map((item, index) => (
        <div key={index} className="flex flex-row items-start justify-center">
          <div
            className="flex flex-col justify-center items-center gap-y-2 smooth-visiility"
            style={{ animationDelay: (index + 1) * 0.5 + 1 + 's' }}
          >
            <SvgCircle item={item} width={88} />
            <p className="font-normal text-ol">{item.title}</p>
          </div>
          {index !== props.attributes.length - 1 && (
            <div className="h-[88px] flex justify-center items-center">
              <PlusIcon />
            </div>
          )}
        </div>
      ))}
    </div>
  ) : (
    <></>
  )
}

export default FormulaBlock
