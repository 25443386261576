import { Answers } from '@/types/types'

export const getValue = (fieldName: string, object: Answers) => {
  for (const arr of Object.values(object)) {
    for (const item of arr) {
      for (const [key, value] of Object.entries(item)) {
        if (key === fieldName) {
          return Array.isArray(value) && value.length > 1 ? value : value[0]
        }
      }
    }
  }
  return null
}
