import { ImageTitleInterface } from '@/types/types'
import React from 'react'

interface SvgCircleProps {
  width: number
  item: ImageTitleInterface
}

const SvgCircle = (props: SvgCircleProps) => {
  return (
    <div
      style={{
        width: props.width + 'px',
        height: props.width + 'px',
      }}
      className="rounded-full bg-formulaCircle flex justify-center items-center"
    >
      <img src={props.item.img} alt={props.item.title} className="object-contain" />
    </div>
  )
}

export default SvgCircle
