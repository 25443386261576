import React from 'react'

const HeaderBlur = (props: { className?: string }) => {
  return (
    <div className={`w-full h-1/4 z-0 relative ${props.className ? props.className : ''}`}>
      <div className="absolute -top-1/4 translate-y-1/4 left-0 w-1/3 h-full scale-[3]">
        <picture>
          <source srcSet="/img/Ellipse1.webp" type="image/webp" />
          <img className="object-cover w-full h-full" src="/img/Ellipse1.png" alt="fancy-face-bg" />
        </picture>
      </div>
      <div className="absolute left-1/2 -translate-x-1/2 w-1/3 h-full scale-[3]">
        <picture>
          <source srcSet="/img/Ellipse2.webp" type="image/webp" />
          <img className="object-cover w-full h-full" src="/img/Ellipse2.png" alt="fancy-face-bg" />
        </picture>
      </div>
      <div className="absolute top-0 w-1/3 right-0 h-full scale-[3]">
        <picture>
          <source srcSet="/img/Ellipse3.webp" type="image/webp" />
          <img className="object-cover w-full h-full" src="/img/Ellipse3.png" alt="fancy-face-bg" />
        </picture>
      </div>
    </div>
  )
}

export default HeaderBlur
