import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PaywallContent } from '@/types/types'

interface TimerProps {
  onTimerExpired: () => void
  isDiscount45: boolean
  paywallContent: PaywallContent
}

export default function Timer(props: TimerProps) {
  const { t } = useTranslation()
  const [endTime, setEndTime] = useState<Date | null>(null)
  const [timeDifference, setTimeDifference] = useState<number | null>(null)

  const startTimer = () => {
    if (endTime) {
      const intervalId = setInterval(() => {
        const currentTime = new Date()
        const difference = endTime.getTime() - currentTime.getTime()
        if (difference <= 0) {
          clearInterval(intervalId)
          setTimeDifference(0)
        } else {
          setTimeDifference(difference)
        }
      }, 1000)
      return () => clearInterval(intervalId)
    }
  }

  useEffect(() => {
    const storedEndTime = localStorage.getItem('endTimer')
    if (storedEndTime) {
      setEndTime(new Date(storedEndTime))
    }
  }, [])

  useEffect(() => {
    if (endTime) {
      const currentTime = new Date()
      const difference = endTime.getTime() - currentTime.getTime()
      if (difference > 0 && !props.isDiscount45) {
        startTimer()
      } else {
        setTimeDifference(0)
      }
    }
  }, [endTime])

  useEffect(() => {
    if (timeDifference === 0) {
      props.onTimerExpired()
    }
  }, [timeDifference])

  const minutes = Math.floor((timeDifference ?? 0) / (1000 * 60))
  const seconds = Math.floor(((timeDifference ?? 0) % (1000 * 60)) / 1000)

  return (
    <>
      {endTime !== null && (
        <p className="font-semibold text-questionStartName">
          {t(props.paywallContent.yourOffer30ExpiresIn as string)} {minutes < 10 ? '0' : ''}
          {minutes}:{seconds < 10 ? '0' : ''}
          {seconds}
        </p>
      )}
    </>
  )
}
