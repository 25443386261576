import React from 'react'

const Preloader = () => {
  return (
    <div className="absolute top-0 left-0 w-full z-10 h-full flex justify-center items-center bg-paywallGradient">
      <div className="preloader"></div>
    </div>
  )
}

export default Preloader
