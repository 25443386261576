import React, { useEffect, useState } from 'react'
import Layout from '@/components/Layout'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
// import PaddleCheckout from '@/components/PaddleCheckout/Paddle'
import { useTranslation } from 'react-i18next'
import { backCheckout, trackInitCheckout } from '@/helpers/facebookPixelEvents'
import StripeCheckout from '@/components/StripeCheckout/Stripe'

export default function Checkout() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState(true)
  const hasQuizResult = localStorage.getItem('quizResult') ?? false
  const plan = JSON.parse(localStorage.getItem('activePlan') ?? '')
  const paymentMethod = 'stripe'
  const paywallContent = location?.state?.paywallContent ?? ''

  const handleReturnToPaywall = () => {
    backCheckout()
    navigate(-1)
  }

  const handleCloseCheckout = () => {
    localStorage.setItem('isDiscount45', 'true')
    handleReturnToPaywall()
  }

  useEffect(() => {
    if (!plan || !searchParams || !hasQuizResult) {
      handleReturnToPaywall()
    }
    window.scrollTo(0, 0)
    trackInitCheckout(plan.name, 'InitiateCheckout_' + paymentMethod)
  }, [])

  return (
    <Layout>
      {hasQuizResult && (
        <div className="px-4 pt-1 flex flex-col h-full pb-6">
          {isLoading && (
            <div className="fixed bottom-0 z-30 bg-background left-0 w-full h-[calc(100%-60px)]">
              <div className="spinner z-30 my-10 mx-auto"></div>
            </div>
          )}
          <div className="relative flex flex-row justify-center items-center pt-6 pb-4">
            <button onClick={handleCloseCheckout} className="w-6 h-6 absolute right-0">
              <img src={'/img/close-square.svg'} alt="" />
            </button>
            <h1 className="font-bold text-questionStartName">{t('Select payment method')}</h1>
          </div>
          {paymentMethod === 'stripe' && (
            <div className="flex w-full shrink grow basis-auto">
              <StripeCheckout
                paywallContent={paywallContent}
                handleIsLoading={(loading: boolean) => setIsLoading(loading)}
                plan={plan}
                searchParams={searchParams}
              />
            </div>
          )}
          {/* {paymentMethod === 'paddle' && ( */}
          {/*  <div className=""> */}
          {/*    <PaddleCheckout */}
          {/*      paywallContent={paywallContent} */}
          {/*      handleIsLoaded={() => setIsLoading(false)} */}
          {/*      plan={plan} */}
          {/*      searchParams={searchParams} */}
          {/*    /> */}
          {/*  </div> */}
          {/* )} */}
        </div>
      )}
    </Layout>
  )
}
