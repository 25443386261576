import { UserRegisterDataInterface } from '@/types/types'

export const createAccount = async (
  customer: UserRegisterDataInterface,
  customerPortalID: string,
): Promise<{ status: number; body: any }> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_MODE === 'dev' ? process.env.REACT_APP_BACKEND_URL_DEV : process.env.REACT_APP_BACKEND_URL}/api/v1/auth/sign-up?_id=${customerPortalID}&origin=WEB`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(customer),
      },
    )
    const data = await response.json()
    return { status: response.status, body: data }
  } catch (e) {
    return { status: 412, body: { error: e } }
  }
}
