interface LogEntry {
  timestamp: string
  payload: any
  response: any
}

const logPixelRequest = async (payload: any, response: any, email: string) => {
  payload.data[0].user_data.em = [email]
  const log: LogEntry = {
    timestamp: new Date().toISOString(),
    payload,
    response,
  }

  const formData = new FormData()
  formData.append('time', log.timestamp)
  formData.append('payload', JSON.stringify(log.payload))
  formData.append('response', JSON.stringify(log.response))

  fetch(
    'https://script.google.com/macros/s/AKfycbyqJBPcASAdfm1vqyT4KIKElcKworrKM6Sll-sYbUMhbJAeSzhY29Qw-T_POeFbUnftBQ/exec',
    {
      method: 'POST',
      body: formData,
    },
  )
    .then((res) => res.json())
    .then((data) => {
      console.log(data)
    })
    .catch((err) => console.log(err))

  return log.response
}

export { logPixelRequest }
