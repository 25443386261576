import ReactPixel from 'react-facebook-pixel'
import { sha256 } from 'js-sha256'
import Cookies from 'cookies-js'
import * as amplitude from '@amplitude/analytics-browser'
import { purchaseFacebookApi } from '@/helpers/facebookApi'
import { Answers } from '@/types/types'
import { getValue } from '@/helpers/getObjectValue'

export const trackPageView = () => {
  ReactPixel.pageView()
}
export const trackLead = () => {
  ReactPixel.track('Lead')
}
export const trackInitCheckout = (plan: string, event: string) => {
  ReactPixel.track('InitiateCheckout')
  amplitude.track(event, { plan })
}
export const trackPurchase = () => {
  const isDiscount45 = localStorage.getItem('isDiscount45')
  const countryCode = localStorage.getItem('countryCode')
  const activePlanString = localStorage.getItem('activePlan')
  const storedData = localStorage.getItem('quizResult')
  const answers: Answers = storedData ? JSON.parse(storedData) : false
  const email = answers ? (getValue('email', answers) as string) : ''
  const activePlan = activePlanString ? JSON.parse(activePlanString) : false
  const price = activePlan
    ? isDiscount45
      ? activePlan.discountedPrice45
      : activePlan.discountedPrice30
    : 0
  if (price !== 0) {
    const predictedPrice = price + 1.42 * activePlan.originalPrice
    const AmplitudeID = amplitude.getSessionId()
    const time = Math.floor(new Date().getTime() / 1000.0)
    const body = {
      data: [
        {
          event_name: 'Purchase',
          event_time: time,
          action_source: 'website',
          event_id: AmplitudeID,
          user_data: {
            em: [sha256(email ?? '')],
            country: [sha256(countryCode ?? '')],
            external_id: [sha256(String(AmplitudeID))],
            fbc: Cookies.get('_fbc'),
            fbp: Cookies.get('_fbp'),
            client_ip_address: localStorage.getItem('ip'),
            client_user_agent: window.navigator.userAgent,
          },
          custom_data: {
            currency: 'USD',
            value: price,
            predicted_ltv: predictedPrice.toFixed(2),
          },
        },
      ],
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    fbq(
      'track',
      'Purchase',
      {
        currency: 'USD',
        value: price,
        predicted_ltv: predictedPrice.toFixed(2),
      },
      { eventID: AmplitudeID },
    )
    amplitude.track('Purchase')
    purchaseFacebookApi(body, email).then((res) => {
      if (res.error) {
        trackConversationsApiErrors(res.error, body)
      }
    })
  }
}
export const selectPaymentMethod = (method: string) => {
  amplitude.track('select_payment_method', { method })
}
export const clickPurchaseButton = (method: string) => {
  amplitude.track('click_purchase_button', { method })
}
export const backCheckout = () => {
  amplitude.track('back_checkout')
}
export const trackPaymentErrors = (e: any) => {
  amplitude.track('payment_error', { error: e })
}
export const trackConversationsApiErrors = (e: any, payload: any) => {
  amplitude.track('conversations_api_error', { payload, error: e })
}
export const trackEmail = (event: string, email: string) => {
  amplitude.track(event, { email })
}
export const trackSecondPage = () => {
  amplitude.track('2nd_page_view')
}
export const trackLoadedPayload = () => {
  amplitude.track('paywall_loaded')
}
export const trackLoadedApplePay = () => {
  amplitude.track('applepay_loaded')
}
export const trackClickApplePay = () => {
  amplitude.track('applepay_button_submited')
}
export const trackDefaultPaymentLoaded = () => {
  amplitude.track('default_payment_loaded')
}
export const trackDiscount = (event: string) => {
  amplitude.track(event)
}
