import React, { useEffect } from 'react'

interface ErrorMessageProps {
  isOpen: boolean
  onClose: (selectPlan: boolean) => void
}

const ErrorMessage = (props: ErrorMessageProps) => {
  useEffect(() => {
    if (props.isOpen) {
      document.body.style.overflowY = 'hidden'
    } else {
      document.body.style.overflowY = 'auto'
      document.body.style.paddingRight = '0px'
    }
  }, [props.isOpen])

  return (
    <div className="fixed top-0 left-1/2 -translate-x-1/2 h-full px-6 backdrop-blur-sm z-10 w-full flex items-center">
      <button
        onClick={() => props.onClose(false)}
        className="absolute top-0 left-0 w-full h-full bg-black opacity-0"
      ></button>
      <div className="w-full bg-white p-6 rounded-2xl relative z-10 shadow-button">
        <svg
          width="64"
          height="64"
          className="mx-auto"
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64ZM44.2936 44.27C45.6799 42.889 45.6799 40.6484 44.2936 39.2632L37.0357 32.0117L44.2936 24.7561C45.6799 23.3751 45.6799 21.0937 44.2936 19.7086C42.9072 18.3194 40.6646 18.3194 39.2375 19.7086L32.0204 26.956L24.7625 19.7086C23.3354 18.3194 21.0928 18.3194 19.7064 19.7086C18.3201 21.0937 18.3201 23.3751 19.7064 24.7561L26.9643 32.0117L19.7064 39.2225C18.3201 40.6484 18.3201 42.889 19.7064 44.27C20.3996 44.9626 21.3374 45.3333 22.2345 45.3333C23.1723 45.3333 24.0693 44.9626 24.7625 44.27L32.0204 37.0633L39.2783 44.27C39.9715 45.0074 40.8685 45.3333 41.7655 45.3333C42.7034 45.3333 43.6004 44.9626 44.2936 44.27Z"
            fill="#FCFCFC"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64ZM44.2936 44.27C45.6799 42.889 45.6799 40.6484 44.2936 39.2632L37.0357 32.0117L44.2936 24.7561C45.6799 23.3751 45.6799 21.0937 44.2936 19.7086C42.9072 18.3194 40.6646 18.3194 39.2375 19.7086L32.0204 26.956L24.7625 19.7086C23.3354 18.3194 21.0928 18.3194 19.7064 19.7086C18.3201 21.0937 18.3201 23.3751 19.7064 24.7561L26.9643 32.0117L19.7064 39.2225C18.3201 40.6484 18.3201 42.889 19.7064 44.27C20.3996 44.9626 21.3374 45.3333 22.2345 45.3333C23.1723 45.3333 24.0693 44.9626 24.7625 44.27L32.0204 37.0633L39.2783 44.27C39.9715 45.0074 40.8685 45.3333 41.7655 45.3333C42.7034 45.3333 43.6004 44.9626 44.2936 44.27Z"
            fill="#D21E49"
          />
        </svg>
        <h2 className="my-6 p-0">
          Payment failed <br />
          Please try again
        </h2>
        <div className="px-4">
          <button
            onClick={() => props.onClose(true)}
            className="uppercase mb-4 w-full text-center py-2 rounded-button bg-[#D21E49] text-white font-semibold"
          >
            TRY AGAIN
          </button>
        </div>
      </div>
    </div>
  )
}
export default ErrorMessage
