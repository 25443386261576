import { PaymentPlan, Plan, PaymentPlanPaddle } from '@/types/types'

export const formatPlan = (plan: Plan, isDiscount45: boolean) => {
  return {
    name: plan.name,
    originalPrice: plan.originalPrice,
    stripeProductID: plan?.stripeProductID,
    nextProductStripeID: plan?.nextProductStripeID,
    stripeCouponID: isDiscount45
      ? plan.stripeDiscounted45CouponID
      : plan.stripeDiscounted30CouponID,
    paddlePriceID: plan?.paddlePriceID,
    nextProductPaddleID: plan?.nextProductPaddleID,
    paddleCouponID: isDiscount45
      ? plan?.paddleDiscounted45CouponID
      : plan?.paddleDiscounted30CouponID,
    discountedPrice: isDiscount45 ? plan.discountedPrice45 : plan.discountedPrice30,
  } as PaymentPlan
}

export const formatPlanPaddle = (plan: PaymentPlan) => {
  return {
    paddlePriceID: plan?.paddlePriceID,
    nextProductPaddleID: plan?.nextProductPaddleID,
    paddleCouponID: plan.paddleCouponID,
  } as PaymentPlanPaddle
}
